/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Table } from '../../common/AntdUiComponents';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';

const List = ({ onSelectTitle, data, pagination, tableChange, sendStatus }) => {
  const [tagsStatus, setTagsStatus] = useState(true);
  const [tagList, setTaglist] = useState([]);
  const statusTag_class = tagsStatus ? 'activeButton' : 'inactiveButton';
  const orderedTag_class = tagsStatus ? 'inactiveButton' : 'activeButton';

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width:'200px',
      sortDirections: ['descend'],
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
      render: text => (
        <span>
          {text===null?<span>-</span>:<u>{text && text.replace('null','')}</u>}

        </span>
      ),
    },
   {
      title: 'Company Name',
      dataIndex: 'company',
      key: 'companycompany',
      sortDirections: ['descend'],
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
      render: text => (
        <span>
           {text===null?<span>-</span>:<span>{text }</span>}
        </span>
      ),
    },
    {
      title: 'Shipping Address',
      dataIndex: 'address',
      key: 'address',
      sortDirections: ['descend'],
      sorter: (a, b) => {
        a = a.address || '';
        b = b.address || '';
        return a.localeCompare(b);
      },
      render: text => <span>{text !== '' ? text : <span>-</span>}</span>,
    },
    {
      title: 'Tag ID',
      dataIndex: 'tagIdentificationNumber',
      sortDirections: ['ascend','descend','ascend'],
      key: 'tagIdentificationNumber',
      sorter: (a, b) => {
        a = a.tagIdentificationNumber || '';
        b = b.tagIdentificationNumber || '';
        return a.localeCompare(b);
      },
      render: text => <span>{text !== null ? text : <span>-</span>}</span>,
      align: 'center',
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      sortDirections: ['ascend','descend','ascend'],
      key: 'orderDate',
      sorter: (a, b) => {
        a = a.orderDate || '';
        b = b.orderDate || '';
        return a.localeCompare(b);
      },
      render: text => <span>{moment(text).format('MM/DD/YYYY hh:mm A')}</span>,
      align: 'center',
    },
    {
      title: 'Shipped Date',
      dataIndex: 'shippedDate',
      sortDirections: ['ascend','descend','ascend'],
      key: 'shippedDate',
      sorter: (a, b) => {
        a = a.shippedDate || '';
        b = b.shippedDate || '';
        return a.localeCompare(b);
      },
      render: text => (
        <span>
          {text !== null
            ? moment(text).format('MM/DD/YYYY hh:mm A')
            : 'Not Shipped Yet'}
        </span>
      ),
      align: 'center',
    },
    {
      title: 'Delivery Date',
      dataIndex: 'deliveryDate',
      sortDirections: ['ascend','descend','ascend'],
      key: 'deliveryDate',
      width:"220px",
      sorter: (a, b) => {
        a = a.deliveryDate || '';
        b = b.deliveryDate || '';
        return a.localeCompare(b);
      },
      render: text => (
        <span>
          {text !== null ? moment(text).format('MM/DD/YYYY hh:mm A') : '-'}
        </span>
      ),
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'tagOrderStatus',
      sortDirections: ['ascend','descend','ascend'],
      width:'130px',
      align: 'center',
      key: 'tagOrderStatus',
      sorter: (a, b) => {
        a = a.tagOrderStatus || '';
        b = b.tagOrderStatus || '';
        return a.localeCompare(b);
      },
      render: text => {
        if (text === 'DELIVERED') {
          return <span className='deliveredClass'>{text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}</span>;
        }
        return <span>{text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}</span>;
      },
    },
  ];

  useEffect(() => {
    setTaglist(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handleSubmit = (e, status, name) => {
    e.preventDefault();
    setTagsStatus(status);
    // if (!status && data !== null) {
    //   const deliveredData = data.filter(
    //     del => del.tagOrderStatus === 'DELIVERED'
    //   );
    //   setTaglist(deliveredData);
    // } else {
    //   setTaglist(data);
    // }
    if(status){
      sendStatus('');
    }else{
      sendStatus('DELIVERED');
    }
    setTaglist(data);
    onSelectTitle(name);
  };

  return (
    <div className='Basecontainer'>
      <Row>
        <Col span={12}>
          <ReuseButton
            text='Status of Ordered Tags'
            className={`TagsToogle ${statusTag_class}`}
            onClick={e => {
              handleSubmit(e, true, 'Status of Ordered Tags');
            }}
          />
        </Col>
        <Col span={12}>
          <ReuseButton
            text='Ordered Tag History'
            className={`TagsToogle ${orderedTag_class}`}
            onClick={e => {
              handleSubmit(e, false, 'Ordered Tag History');
            }}
          />
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24} className='tablePadding'>
          {tagsStatus ? (
            <p className='TitleText pad-top-15'>
              Here are all the tags that have been ordered.
            </p>
          ) : (
            <p className='TitleText pad-top-15'>
              Here’s the history of all ordered tags.
            </p>
          )}

          <Table
            className='BaseTable'
            dataSource={tagList}
            columns={columns}
            showHeader
            pagination={pagination}
            onChange={tableChange}
            scroll={tagList.length == 0 ? {} :{ x: 'max-content' }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default List;
