/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
  NavLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Layout, Menu } from '../common/AntdUiComponents';
import AppRoutes from '../config/AppRoutes';
import logo from '../images/Unytaglogo.png';
import HeaderLinks from '../layouts/HeaderLinks';
import OverviewIcon from '../images/Overview.svg';
import Overview_Active from '../images/overview_icon.png';
import TagsIcon from '../images/tags_icon.png';
import Tags_Active from '../images/tags_active.svg';
import TollAuthIcon from '../images/tollauth_icon.png';
import TollAuth_Active from '../images/tollauth_active.svg';
import UserIcon from '../images/user_icon.png';
import User_Active from '../images/user_active.svg';
import EnterpriseIcon from '../images/enterprise_icon.png';
import Enterprise_Active from '../images/enterprise_active.svg';
import SupportIcon from '../images/support_icon.png';
import Support_active from '../images/support_active.svg';


const { Sider, Content } = Layout;

const items = [
  {
    key: '1',
    label: 'Overview',
    path: '/unytag/web/overview',
    icon: OverviewIcon,
    access: ['UNYTAG', 'ENTERPRISE'],
  },
  {
    key: '2',
    label: 'Tags',
    path: '/unytag/web/tags',
    icon: TagsIcon,
    access: ['UNYTAG', 'ENTERPRISE'],
  },
  {
    key: '3',
    label: 'Users',
    path: '/unytag/web/users',
    icon: UserIcon,
    access: ['UNYTAG'],
  },
  {
    key: '4',
    label: 'Toll Authorities',
    path: '/unytag/web/tollauthority',
    icon: TollAuthIcon,
    access: ['UNYTAG','TOLLAUTHORITY'],
  },
  {
    key: '5',
    label: 'Enterprises',
    path: '/unytag/web/enterprise',
    icon: EnterpriseIcon,
    access: ['UNYTAG'],
  },
  {
    key: '5',
    label: 'Account',
    path: '/unytag/web/enterprise',
    icon: UserIcon,
    access: ['ENTERPRISE'],
  },
  // {
  //   key: '7',
  //   label: 'Support',
  //   path: '/unytag/web/support',
  //   icon: SupportIcon,
  //   access: ['UNYTAG', 'ENTERPRISE','TOLLAUTHORITY'],
  // },
];

const Dashboard = () => {
  const user = localStorage.getItem('user');
  const userDetails = user!==''? JSON.parse(user) : { };
  const location = useLocation();
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState(
    items.find((_item) => location.pathname.startsWith(_item.path))
      ? items.find((_item) => location.pathname.startsWith(_item.path)).key
      : '1',
  );
  const onClickMenu = (item) => {
    const clicked = items.find((_item) => _item.key === item.key);
    history.push(clicked.path);
  };
  useEffect(() => {
    setSelectedKey(
      items.find((_item) => location.pathname.startsWith(_item.path))
        ? items.find((_item) => location.pathname.startsWith(_item.path)).key
        : '1',
    );
  }, [location]);

  const fetchIcon = (name, key) => {
    if (key === selectedKey) {
      switch (name) {
        case OverviewIcon:
          return Overview_Active;
        case TagsIcon:
          return Tags_Active;
        case TollAuthIcon:
          return TollAuth_Active;
        case UserIcon:
          return User_Active;
        case EnterpriseIcon:
          return Enterprise_Active;
        case SupportIcon:
          return Support_active;
        default:
          return Overview_Active;
      }
    }
    return name;
  };

  const rediredtToMainPage = () => {
    history.push('/unytag/web/overview')
  }

  return (
    <BrowserRouter>
      <div className="MainLayout">
        <div>
          <Layout className="LayoutDisplay">
            <Sider className="SidemenuDisplay">
              <div className="logo" onClick={rediredtToMainPage}>
              <NavLink to='/unytag/web/overview'>
                <img src={logo} width="30" alt="UNYTAG" />
                <span className="logoText">Unytag</span>
                </NavLink>
              </div>
              <Menu
                className="SidemenuText"
                mode="inline"
                defaultSelectedKeys={[selectedKey]}
                selectedKeys={[selectedKey]}
                onClick={onClickMenu}
              >
                {items.map((item) => {
                  return (
                    item.access.includes(userDetails.userType) && (
                      <Menu.Item
                        className="SidemenuBox"
                        key={item.key}
                        icon={
                          <img
                            className="SidemenuIcon"
                            src={fetchIcon(item.icon, item.key)}
                            alt="i"
                            width="20"
                          />
                        }
                      >
                        <NavLink to={item.path}>{item.label}</NavLink>
                      </Menu.Item>
                    )
                  );
                })}
              </Menu>
            </Sider>
            <Layout className="site-layout">
              <HeaderLinks />
              <Content
                className="site-layout-background contentdisplay"
                style={{
                  padding: '40px 40px 0px 30px',
                }}
              >
                <Switch>
                  {AppRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      key={route.key}
                    />
                  ))}
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Dashboard;
