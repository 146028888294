/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Button, Form } from '../../common/AntdUiComponents';
import TollForm from './TollForm';
import {
  saveTollAuthority,
  fetchtollAuthorityById,
} from '../../api/tollAuthority';
import { alertMessage,validateAddressForm } from '../../common/Common';


const roles = ['User', 'Admin'];

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const Profile = ({ id }) => {
  const [inProgress, setInProgress] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [disableFields, setDisableFields] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [form] = Form.useForm();
  const params = useParams();
  const [state, setState] = useState({
    name: '',
    email: '',
    toll: '',
    phone: '',
    address: '',
    role: '',
  });

  const getTollDetails = () => {
    fetchtollAuthorityById(params.id).then((result) => {
      if (result.success) {
        const { name } = result.record;
        const email = 'random@gmail.com';
        const toll = 'Toll 22';
        const phone = '197831152135';
        const { streetNumber } = result.record;
        const { streetName } = result.record;
        // eslint-disable-next-line no-shadow
        const { state } = result.record;
        const { city } = result.record;
        const { zipCode } = result.record;
        const address = {
          address_line1: streetNumber,
          address_line2: streetName,
          state,
          city,
          zipcode: zipCode,
          country: 'US',
        };
        const role = 'User';
        const values = {
          name,
          email,
          toll,
          phone,
          address,
          role,
        };
        setState(values);
      }
    });
  };

  useEffect(() => {
    const isEdit = () => {
      if (params.name !== undefined) {
        setIsEditProfile(true);
      } else {
        setIsEditProfile(false);
      }
    };
    isEdit();
    getTollDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoleChange = (e, value) => {
    setState({
      ...state,
      [e]: value,
    });
  };

  const handleProfile = (type) => {
    setIsEditProfile(!isEditProfile);
    setDisableFields(!disableFields);
    if (type === 'save') {
      setDisableBtn(true)
      setInProgress(true);
      const formData = {};
      let firstName= '';
      let lastName = '';
      const nameObj = state.name.trim().split(" ");
      if(nameObj.length===1){
        firstName = nameObj[0];
        lastName=''
      }else{
        firstName = nameObj[0];
        let lastnameObjArray = nameObj.slice(1);
        let lastnameObj = lastnameObjArray.join(' ');
        lastName = lastnameObj
      }
      const name = `${firstName} ${lastName}`;
      formData.name =name.trim();
      formData.city =state.address.city;
      formData.state =state.address.state;
      formData.streetName = state.address.address_line2;
      formData.id = params.id;
      formData.streetNumber = state.address.address_line1;
      formData.zipCode = state.address.zipcode;
      if(re.test(String(state.email).toLowerCase())){
        if (state.phone.length>=11) {
      saveTollAuthority(false, formData, params.id).then((result) => {
        if (result.success) {
          alertMessage('ALL CHANGES HAVE BEEN SAVED!');
          setInProgress(false);
        } else {
          setDisableBtn(false)
          alertMessage(result.errors.message.message.toString().toUpperCase(), 'error', 3);
          setInProgress(false);
        }
      });
    }else{
      setDisableBtn(false)
      setDisableFields(false);
      setIsEditProfile(false);
      alertMessage('PLEASE ENTER A CORRECT PHONE NUMBER', 'error', 3);
    }
  }else{
    setDisableBtn(false)
    setDisableFields(false);
    setIsEditProfile(false);
    alertMessage('PLEASE PROVIDE A VALID EMAIL ADDRESS', 'error', 3);
  }
    }
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    const addrressError = validateAddressForm(obj);
    if(addrressError){
      const errors = [];
      errors.push(`${addrressError}`);
      setErrors(errors)
      showError(true)
    }else{
      showError(false)
    }
    setState({
      ...state,
      [addressType]: obj,
    });
  };

  const handleChange = (key) => (value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setInProgress(true);
    const formData = {};
    let firstName= '';
      let lastName = '';
      const nameObj = state.name.trim().split(" ");
      if(nameObj.length===1){
        firstName = nameObj[0];
        lastName=''
      }else{
        firstName = nameObj[0];
        let lastnameObjArray = nameObj.slice(1);
        let lastnameObj = lastnameObjArray.join(' ');
        lastName = lastnameObj
      }
    const name  = `${firstName} ${lastName}`;
    formData.name = name.trim();
    formData.city =
      state.address.city === ''
        ? state.address.address_line1
        : state.address.city;
    formData.state = state.address.state;
    formData.streetName = state.address.address_line2;
    formData.id = params.id;
    formData.streetNumber = state.address.address_line1;
    formData.zipCode = state.address.zipcode;
    console.log(formData)
    saveTollAuthority(false, formData, params.id).then((result) => {
      if (result.success) {
        alertMessage('ALL CHANGES HAVE BEEN SAVED!');
        setInProgress(false);
      } else {
          if(Array.isArray(result.errors.message.message)){
            alertMessage(result.errors.message.message.toString().toUpperCase(), 'error', 3);
          }
          else{
            alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
          }
        setInProgress(false);
      }
    });
    setState({});
  };

  return (
    <div>
      <p className="PageTitle">
        <Row type="flex" justify="space-between">
          {params.name !== 'admin' && (
            <span className="profileTitle">{params.name}</span>
          )}
          {isEditProfile ? (
            <Button
              className="AddButton"
              onClick={() => {
                handleProfile('edit');
              }}
            >
              {params.name !== undefined ? 'EDIT PROFILE' : 'ADD PROFILE'}
            </Button>
          ) : (
            <Button
              className="btn-style btn-success SaveButton"
              disabled={
                state.name.trim() === '' ||
                state.email === '' ||
                error||
                state.address === '' ||
                state.role === '' ||
                !re.test(String(state.email).toLowerCase())
              }
              onClick={() => {
                handleProfile('save');
              }}
            >
              SAVE
            </Button>
          )}
        </Row>
      </p>
      <div className="Basecontainer">
        <TollForm
          handleChange={handleChange}
          handleOnAddressChange={handleOnAddressChange}
          handleRoleChange={handleRoleChange}
          submitForm={submitForm}
          state={state}
          roles={roles}
          errors={errors}

          error={error}
          hideCreate
          disableBtn={disableBtn}
          disableFields={disableFields}
        />
      </div>
    </div>
  );
};

export default Profile;
