import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  Icon,
  Form,
  Button,
} from '../../common/AntdUiComponents';
import { alertMessage } from '../../common/Common';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import TollForm from './TollForm';
import {validateAddressForm} from '../../common/Common'

const roles = ['User', 'Admin'];
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const List = ({
  onSelectTitle,
  data,
  pagination,
  tableChange,
  createToll,
  showModal,
  stateCLear,
  disableSubmitBtn,
}) => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [form] = Form.useForm();
  const [addressType,setAddressType] = useState('')
  const [addressObj,setAddressObj] = useState({})
  const [state, setState] = useState({
    name: '',
    email: '',
    toll: '',
    phone: '',
    address: '',
    role: '',
  });
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: () => (
        <span className='textFields'>
          <Icon type='arrow-right' style={{ fontSize: '30px' }} />
        </span>
      ),
      align: 'right',
    },
  ];

  useEffect(() => {
    setShowViewModal(showModal);
    setDisableBtn(disableSubmitBtn);
    if (stateCLear) {
      setState({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, stateCLear, disableSubmitBtn]);

  const handleTitleChange = (name, id) => {
    onSelectTitle(name, 'category', id);
  };

  const handleRoleChange = (e, value) => {
    setState({
      ...state,
      [e]: value,
    });
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    setAddressType(addressType)
    setAddressObj(obj)
    console.log(obj)
    const addrressError = validateAddressForm(obj);
    if(addrressError){
      const errors = [];
      errors.push(`${addrressError}`);
      setErrors(errors)
      showError(true)
    }else{
      showError(false)
    }
    setState({
      ...state,
      [addressType]: obj,
    });
  };

  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const submitForm = e => {
    e.preventDefault();
    form.setFieldsValue({
      email: '',
      name:'',
      role:''
    });
    if (state.name.trim().length!==0) {
    if (re.test(String(state.email).toLowerCase())) {
      if (state.phone.length >= 11 || state.phone === '') {
        createToll(true, state);
      } else {
        alertMessage('PLEASE ENTER A CORRECT NUMBER', 'error', 3);
      }
    } else {
      alertMessage('PLEASE PROVIDE A VALID EMAIL ADDRESS', 'error', 3);
    }
  }else{
    alertMessage('PLEASE PROVIDE A VALID NAME', 'error', 3);
  }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisableBtn(false);
    setState({
      name: '',
      email: '',
      toll: '',
      phone: '',
      address: '',
      role: '',
    });
    setShowViewModal(true);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
    showError(false)
  };

  const onFocus = () => {
    handleOnAddressChange(addressType,'','',addressObj);
    console.log('sdsdsd')
  }

  const renderViewModel = () => {
    return (
      <BaseModal width='70%' onCancel={() => handleOnModalClose()}>
        <TollForm
          handleChange={handleChange}
          handleOnAddressChange={handleOnAddressChange}
          handleRoleChange={handleRoleChange}
          disableBtn={disableBtn}
          submitForm={submitForm}
          errors={errors}
          error={error}
          state={state}
          roles={roles}
          onFocus={onFocus}
          hideCreate={false}
          disableFields={false}
        />
      </BaseModal>
    );
  };

  return (
    <div className='Basecontainer'>
      <Row gutter={30}>
        <Col span={24} className='tablePadding  mar-top-20'>
          <p className='TitleText mar-bottom-20'>
            <span> Manage toll authority accounts here.</span>
          </p>
          <p className='TitleText mar-bottom-20 addTollAuthBtn'>
            <Button
              className='AddButton'
              onClick={e => {
                handleSubmit(e);
              }}>
              ADD TOLL AUTHORITY
            </Button>
          </p>
          <Table
            className='BaseTable userCategories'
            dataSource={data}
            columns={columns}
            pagination={pagination}
            onChange={tableChange}
            onRow={record => {
              return {
                onClick: () => {
                  handleTitleChange(record.name, record.id);
                },
              };
            }}
            showHeader={false}
          />
        </Col>
      </Row>
      {showViewModal && renderViewModel()}
    </div>
  );
};

export default List;
