import React from 'react';
import { Row, Col, Table, Icon } from '../../common/AntdUiComponents';

const List = ({ onSelectTitle, data,pagination,tableChange }) => {
  const handleTitleChange = (name, role, id) => {
    onSelectTitle(name, 'Edit Profile', role, id);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, objData) => {
        const { firstName } = objData;
        const lastName = objData.lastName !== null ? objData.lastName : '';
        const name = `${firstName} ${lastName}`;
        return (
          <span>
            {name}({objData.role})
          </span>
        );
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, objData) => {
        const { firstName } = objData;
        const lastName = objData.lastName !== null ? objData.lastName : '';
        const name = `${firstName} ${lastName}`;
        return (
          <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
            <Icon
              type="arrow-right"
              onClick={() => handleTitleChange(name, objData.role, objData.id)}
              style={{ fontSize: '30px' }}
            />
          </span>
        );
      },
      align: 'right',
    },
  ];

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding mar-top-10">
          <p className="TitleText mar-bottom-20">
            Here are all the profiles associated with this enterprise customer.
          </p>
          <Table
            className="BaseTable userCategories"
            dataSource={data}
            columns={columns}
            showHeader={false}
            pagination={pagination}
            onChange={tableChange}
            onRow={(record) => {
              const { firstName } = record;
              const lastName = record.lastName !== null ? record.lastName : '';
              const name = `${firstName} ${lastName}`;
              return {
                onClick: () => {
                  handleTitleChange(name, record.role, record.id);
                },
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default List;
