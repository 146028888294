import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Table, Spin } from '../../common/AntdUiComponents';
import { getAllTags } from '../../api/tags';
import {sorters} from '../../common/Common'

const List = ({ search }) => {
  const [userData, setData] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [pagination, setPagination] = useState({});
  const [perPage,setPerPage] = useState(20)
  const [sortKey,setSortKey] = useState('')
  const [sortOrder,setSortOrder] = useState('')

  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const fetchTags = (value,pageSizeCount,sortkey,sorter) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    getAllTags(value, page, pageSizeCount,sortkey,sorter).then(result => {
      if (result.success) {
        setData(result.records);
        setPagination(result.pagination);
        setInProgress(false);
      } else {
        setData([]);
        setPagination(null);
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    fetchTags(search,perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // eslint-disable-next-line no-shadow
  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize)
    let sorterOrder='';
    let sorterkey=''
    sorter.field = sorter.field === 'name' ? 'firstName':sorter.field
    console.log(sorters(sorter.order))
        sorterOrder = sorters(sorter.order);
      sorterkey = sorters(sorter.order)===''?'': sorter.field;
       setSortKey(sorters(sorter.order)===''?'': sorter.field);
       setSortOrder(sorters(sorter.order))
    fetchTags(search,pagination.pageSize,sorterkey,sorterOrder);
  };


  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend','descend','ascend'],
      render: (text, data) => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          {data.firstName!==null ? <u>{`${data.firstName !== null ? data.firstName : ''} ${
            data.lastName !== null ? data.lastName : ''
          }`}</u>: '-'}

        </span>
      ),
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Tag ID',
      dataIndex: 'tagId',
      key: 'tagId',
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => {
        a = a.tagId || '';
        b = b.tagId || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Last Connected',
      dataIndex: 'lastConnected',
      key: 'lastConnected',
      sortDirections: ['ascend','descend','ascend'],
      align: 'center',
      sorter: (a, b) => {
        a = a.lastConnected || '';
        b = b.lastConnected || '';
        return a.localeCompare(b);
      },
      render: text => {
        return (
          <div>{text != null ? moment(text).format('MM/DD/YYYY hh:mm A') : '-'}</div>
        );
      },
    },
    {
      title: 'Account Type',
      dataIndex: 'accountType',
      sortDirections: ['ascend','descend','ascend'],
      key: 'accountType',
      sorter: (a, b) => {
        a = a.accountType || '';
        b = b.accountType || '';
        return a.localeCompare(b);
      },
      render: (text,data) => {
        return <div>{text != null ? text : '-'}</div>;
      },
    },
  ];


  return (
    <div className='Basecontainer'>
      <Spin spinning={inProgress}>
        <Row gutter={30}>
          <Col span={24} className='tablePadding  mar-top-10'>
            <p className='TitleText mar-bottom-20'>
              View all tags that are active right now.
            </p>
            <Table
              className='BaseTable'
              dataSource={userData}
              columns={columns}
              pagination={
                pagination !== null
                  ? {
                      total: parseInt(pagination.totalCount, 10),
                      current: parseInt(pagination.currentPage, 10),
                      pageSize: parseInt(pagination.perPage, 10),
                      pageSizeOptions: [5,10,20],
                    }
                  : {}
              }
              onChange={(pagination, filters, sorter) =>
                handleTableChange(pagination, filters, sorter)
              }
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default List;
