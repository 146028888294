import React,{useState,useEffect} from 'react';
import { Row, Col, Table, Icon } from '../../common/AntdUiComponents';

const CategoryList = ({ onSelectTitle, name,dataValue }) => {

  const [data, setData] = useState([]);

  const dataSource = [
    {
      key: '1',
      name: 'Users',
      age: 32,
    },
    {
      key: '1',
      name: 'Trip History & Statements',
      age: 32,
    },
    {
      key: '3',
      name: `Payment Method`,
      age: 42,
    },
    {
      key: '4',
      name: 'Profile',
      age: 42,
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: () => (
        <span
          style={{
            textTransform: 'capitalize',
            textAlign: 'right',
            cursor: 'pointer',
          }}
        >
          <Icon type="arrow-right" style={{ fontSize: '30px' }} />
        </span>
      ),
      align: 'right',
    },
  ];

  const handleTitleChange = (ename, nextState) => {
    onSelectTitle(ename, nextState);
  };


  useEffect(() => {
    if (dataValue !== '') {
      const filteredData = dataSource.filter(
        (datas) => datas.name.toLowerCase().includes(dataValue.toLowerCase()),
      );
      setData(filteredData);
    } else {
      setData(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding mar-top-10">
          <p className="TitleText mar-bottom-20">
            View everything that has happened with this user here.
          </p>
          <Table
            className="BaseTable userCategories"
            dataSource={data}
            columns={columns}
            showHeader={false}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleTitleChange(name, record.name);
                },
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CategoryList;
