/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect,useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Spin } from '../../../common/AntdUiComponents';
import { fetchTripHistoryList } from '../../../api/user';
import { fetchEntTripHistoryList } from '../../../api/enterprise/enterprise';
import TripHistoryList from './TripHistoryList';
import { alertMessage,sorters } from '../../../common/Common';
import ReuseInputField from '../ReuseInputField';
import debounce from 'lodash.debounce';

const _expandedRowKeys = new Set();



const NestedTable = ({ record }) => {
  const [showTripDetails, setshowTripDetails] = useState(false);
  const showtripDetails = () => {
    setshowTripDetails(true);
  };
  const hidetripDetails = () => {
    setshowTripDetails(false);
  };
  return (
    <Row>
      {!showTripDetails && (
        <u
          onClick={() => showtripDetails()}
          onKeyPress={() => showtripDetails()}
          role="link"
          tabIndex="0"
          className="pointer"
        >
          Trip Details
        </u>
      )}
      {showTripDetails && (
        <span>
          {record.map((data, index) => {
            return (
              <>
                {index !== 0 && <span> {'>>'} </span>}
                <span>
                  {' '}
                  {`${data.tollPlazaName}: (${data.tollFacilityName}`} -
                </span>
                <b>${data.charge}</b>
                <span>{')'}</span>
              </>
            );
          })}
          &nbsp;{' '}
          <u
            onClick={() => hidetripDetails()}
            onKeyPress={() => hidetripDetails()}
            role="link"
            tabIndex="0"
            className="pointer"
          >
            {' '}
            Hide Details{' '}
          </u>
        </span>
      )}
    </Row>
  );
};

const TripHistory = ({ onSelectTitle, name, data, type }) => {
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const [selectableObj, setSelectableObj] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [userData, setEnterprisesUserData] = useState([]);
  const [expandedRowKeys, setexpandedRowKeys] = useState([]);
  const [perPage,setPerPage] = useState(20)
  const [pagination, setPagination] = useState({});
  const [sortKey,setSortKey] = useState('')
  const [sortOrder,setSortOrder] = useState('')
  const [searchField,setSearchField] = useState('')


  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  useEffect(() => {
    fetchTripHistoryDetails(searchField,perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTripHistoryDetails = (searchField,pageSizeCount,sortkey,sorter) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    console.log(tableOptions)

    if (type === 'user') {
console.log(page)
      console.log('herere')
      fetchTripHistoryList(params.id,searchField,page,
        pageSizeCount,sortkey,sorter).then((result) => {
        if (result.success) {
   const resultData = [];
          console.log(result.records)
          console.log(result.pagination)
          const userRecords = result.records
          const pagination = result.pagination
          setEnterprisesUserData(userRecords);
          setPagination(pagination);
          for (let i = 0; i < result.records.length; i += 1) {
            // eslint-disable-next-line no-param-reassign
            console.log(result.records[i].tagId)
            result.records[i].key = result.records[i].tagId;
            resultData.push(result.records[i].tagId);
          }
          console.log(resultData)
          setexpandedRowKeys(resultData);
          setInProgress(false);
        } else {
           setPagination(null);
          setEnterprisesUserData([]);
          setInProgress(false);
        }
      });
    } else {
      fetchEntTripHistoryList(params.id,searchField,page,
        pageSizeCount,sortkey,sorter).then((result) => {
          const resultData = [];
        if (result.success) {
          console.log(result)
          for (let i = 0; i < result.records.length; i += 1) {
            // eslint-disable-next-line no-param-reassign
            result.records[i].key = result.records[i].tagId;
            resultData.push(result.records[i].tagId);
          }
          console.log(result)
          const entRecords = result.records
          const pagination = result.pagination
          setPagination(pagination);
          setexpandedRowKeys(resultData);
          setEnterprisesUserData(entRecords);
          setInProgress(false);
        } else {
          setPagination(null);
          setEnterprisesUserData([]);
          setInProgress(false);
        }
      });
    }
  };

  const handleSubmit = (e, obj, types) => {
    e.preventDefault();
    const objData = { ...obj, types };
    setSelectableObj(objData);
    setVisible(!visible);
  };
  const downloadreport = () => {

  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter)
    setInProgress(true)
    setEnterprisesUserData([])
    tableOptions = { pagination, filters, sorter };
    console.log(tableOptions)
    setPerPage(pagination.pageSize)
    let sorterOrder='';
    let sorterkey=''
    sorter.field = sorter.field === 'name' ? 'firstName':sorter.field
    console.log(sorters(sorter.order))
        sorterOrder = sorters(sorter.order);
      sorterkey = sorters(sorter.order)===''?'': sorter.field;
       setSortKey(sorters(sorter.order)===''?'': sorter.field);
       setSortOrder(sorters(sorter.order))
       fetchTripHistoryDetails(searchField,pagination.pageSize,sorterkey,sorterOrder);
      // debouncedSave(searchField,pagination.pageSize,sorterkey,sorterOrder);

  };

  const _onExpand = (expanded, record) => {
    _toggleExpandByCaseId(record.key);
  };

  const onRowClick = (key) => {
    _toggleExpandByCaseId(key);
  };

  const _toggleExpandByCaseId = (caseId) => {
    _expandedRowKeys.has(caseId)
      ? _expandedRowKeys.delete(caseId)
      : _expandedRowKeys.add(caseId);
    setexpandedRowKeys(Array.from(_expandedRowKeys.values()));
  };

  const _expandRowRender = (record, index, indent) => {
    return <NestedTable record={record.detailedTransaction} />;
  };

  const handleSearch = (value) => {
    setEnterprisesUserData([]);
    setInProgress(true)
    setSearchField(value);

    debouncedSave(value,5,'','');


  }

  const debouncedSave = useCallback(
    debounce((value,page,sortBy,sortOrder) =>  {
      setEnterprisesUserData([]);
      fetchTripHistoryDetails(value,page,sortBy,sortOrder)}, 1000),
    [], // will be created only once initially
  );


  return (
    <div>
      <Spin spinning={inProgress}>
      <p className="ContainerTitleHeader">

              <span className="PageTitle">Trip History </span>

            <ReuseInputField placeholder="Search..." className="SearchBar"  onChange={handleSearch} />
          </p>
        <div className="Basecontainer">
          <TripHistoryList
          downloadStmnt={downloadreport}
            checkpossible={onRowClick}
            handleSubmit={handleSubmit}
            data={userData}
            type={type}
            pagination={
              pagination !== null
                ? {
                    total: parseInt(pagination.totalCount, 10),
                    current: parseInt(pagination.currentPage, 10),
                    pageSize: parseInt(pagination.perPage, 10),
                    pageSizeOptions: [5,10,20],
                  }
                : {}
            }
            tableChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            expandable={{
              expandedRowKeys,

              onExpand: (expanded, record) => {
                return _onExpand(expanded, record);
              },
              expandedRowRender: (record, index, indent, expanded) => {
                return _expandRowRender(record, index, indent);
              },
              expandIcon: ({ expanded, onExpand, record }) => {
                return expanded ? '' : '';
              },
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default TripHistory;
