import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function getAdminUserDetails(id) {
  const url = ApiUrl(`/unytag/superAdmin/user/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function updateAdmin(id, data) {
  const url = ApiUrl(`/unytag/superAdmin/user/${id}`);
  return Call('put', url, data, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function validatePassword(data) {
  const url = ApiUrl(`/unytag/user/validatepassword`);
  return Call('post', url, data, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function sendInvite(data) {
  const url = ApiUrl(`/unytag/invite/email`);
  return Call('post', url, data, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function updatePassword(email, token, formData) {
  const url = ApiUrl(`/user/updatepassword?email=${email}&token=${token}`);
  return Call('put', url, formData, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}
