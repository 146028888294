import { Call, ApiUrl, ParseGeneralResponse, PageParams } from '../ApiUtils';
import AppConfig from '../../config/AppConfig';

export function fetchEnterprices(
  page = 1,
  perPage = AppConfig.perPage,
  name = '',
  id = ''
) {
  const url = ApiUrl(
    `/unytag/enterprise?${PageParams(
      page,
      perPage
    )}&search=${encodeURIComponent(name)}&sortBy=${id}`
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '', pagination: 'meta' },
  });
}

export function fetchEnterpriceUsers(name) {
  const url = ApiUrl(`/unytag/enterprise/user?enterprise=${name}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function fetchEntTripHistoryList(id,search, page = 1,
  perPage = AppConfig.perPage,orderName = '',order='') {
  const url = ApiUrl(`/unytag/trip/details/enterprise/${id}?&${PageParams(page, perPage)}&searchTerm=${search}&sortBy=${orderName}&sortOrder=${order}`);
  return Call('post', url, {}, ParseGeneralResponse, {
    responseDataKeys: {records: 'records', pagination: 'meta',adminsCount:'adminsCount' },
  });
}

export function fetchEnterpriceUserById(id) {
  const url = ApiUrl(`/unytag/enterprise/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function deleteEnterpriceUserById(id) {
  const url = ApiUrl(`/unytag/enterprise/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function deleteUserById(company, id) {
  const url = ApiUrl(`/unytag/enterprise/user/${id}?enterpriseName=${company}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function freezeAccount(id) {
  const url = ApiUrl(`/unytag/enterprise/togglefreeze/${id}`);
  return Call('put', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function freezeUserTag(company, id) {
  const url = ApiUrl(
    `/unytag/enterprise/togglefreeze/${id}?enterpriseName=${company}`
  );
  return Call('post', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function shipTag(company, id) {
  const url = ApiUrl(
    `/unytag/enterprise/user/tag?enterpriseName=${company}&userId=${id}`
  );
  return Call('post', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function addEnterprises(isNew, data) {
  const method = isNew ? 'post' : 'put';
  const url = ApiUrl(`/unytag/enterprise`);
  const dataParameters = data;
  return Call(method, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function addPayments(data) {
  const method = 'post';
  const url = ApiUrl(`/paymentmethod/add`);
  const dataParameters = data;
  return Call(method, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function updatePayments(data) {
  const url = ApiUrl(`/paymentmethod/update`);
  const dataParameters = data;
  return Call('put', url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function fetchPayments(entId, userId) {
  const url = ApiUrl(
    `/paymentmethod/getpaymentmethoddetails?enterpriseId=${entId}&userId=${userId}`
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function registerNewEntUser(formData,company,usertype,token) {
  const url = ApiUrl(`/unytag/enterprise/user/register?company=${company}&userType=${usertype}&token=${token}`);
  return Call('post', url, formData, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  },false);
}
