import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  Table,
  Modal,
  DatePicker,
  Spin,
} from '../../common/AntdUiComponents';
import AddressForm from '../../common/Addressform';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { fetchTransactions } from '../../api/overview';
import {sorters} from '../../common/Common'

const List = ({ userType, search }) => {
  const [selectableObj, setSelectableObj] = useState('');
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [endDate, setEndDate] = useState('');
  const [error, showError] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [address] = useState('');
  const [perPage,setPerPage] = useState(20);
  const [sortKey,setSortKey] = useState('')
  const [sortOrder,setSortOrder] = useState('')

  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sortDirections: ['descend'],
      render: (text, data) => (
        <span>
          <u>
            {text}&nbsp;{data.lastName}
          </u>
        </span>
      ),
      width: '150px',
      sorter: (a, b) => {
        a = a.firstName || '';
        b = b.firstName || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Tag ID',
      dataIndex: 'tagId',
      key: 'tagId',
      sortDirections: ['descend'],
      sorter: (a, b) => {
        a = a.tagId || '';
        b = b.tagId || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Charge Date',
      dataIndex: 'chargeDate',
      key: 'chargeDate',
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => {
        a = a.chargeDate || '';
        b = b.chargeDate || '';
        return a.localeCompare(b);
      },
      width: '190px',
      render: text => <span>{moment(text).format('MM/DD/YYYY hh:mm A')}</span>,
    },
    {
      title: 'Amt. Charged',
      dataIndex: 'amountCharged',
      key: 'amountCharged',
      sortDirections: ['ascend','descend','ascend'],
      width: '170px',
      sorter: (a, b) => {
        a = a.amountCharged || '';
        b = b.amountCharged || '';
        return a.localeCompare(b);
      },
    },

    {
      title: 'Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => {
        a = a.paymentStatus || '';
        b = b.paymentStatus || '';
        return a.localeCompare(b);
      },
      render: text => <span>{text !== null ? text : '-'}</span>,
      align: 'center',
    },

    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => {
        a = a.transactionId || '';
        b = b.transactionId || '';
        return a.localeCompare(b);
      },
      render: text => <span>{text !== null ? text : '-'}</span>,
      align: 'center',
    },
  ];

  if (userType && userType === 'UNYTAG') {
    columns.push(
      {
        title: 'Amount Due',
        dataIndex: 'amountDue',
        sortDirections: ['ascend','descend','ascend'],
        key: 'amountDue',
        sorter: (a, b) => {
          a = a.amountDue || '';
          b = b.amountDue || '';
          return a.localeCompare(b);
        },
      },
      {
        title: 'Status',
        dataIndex: 'transactionStatus',
        sortDirections: ['ascend','descend','ascend'],
        key: 'transactionStatus',
        sorter: (a, b) => {
          a = a.transactionStatus || '';
          b = b.transactionStatus || '';
          return a.localeCompare(b);
        },
        render: text => <span>{text !== null ? text : '-'}</span>,
        align: 'center',
      }
    );
  }

  useEffect(() => {
    // if (startDate !== '' && endDate !== '' && search !== '') {
      getTransactions(startDate, endDate,search,5);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleModalCancel = () => {
    setSelectableObj('');
    setVisible(!visible);
  };

    // eslint-disable-next-line no-shadow
    const handleTableChange = (pagination, filters, sorter) => {
      tableOptions = { pagination, filters, sorter };
      setPerPage(pagination.pageSize)
      let sorterOrder='';
      let sorterkey=''
      sorter.field = sorter.field === 'name' ? 'firstName':sorter.field
      console.log(sorters(sorter.order))
          sorterOrder = sorters(sorter.order);
        sorterkey = sorters(sorter.order)===''?'': sorter.field;
         setSortKey(sorters(sorter.order)===''?'': sorter.field);
         setSortOrder(sorters(sorter.order))
      getTransactions(startDate, endDate,search,pagination.pageSize,sorterkey,sorterOrder);
    };

  const getTransactions = async (sttDate, edDates,search,pageSizeCount,sortkey,sorter) => {
    const page = tableOptions.pagination.current;
    setProgress(true);
    fetchTransactions(sttDate, edDates,search,page,pageSizeCount,sortkey,sorter).then(result => {
      if (result.success) {
        setProgress(false);
        const userData = result.records;
        setPagination(result.pagination);
        setData(userData);
      } else {
        setProgress(false);
        setData([]);
        setPagination(null);
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
      }
    });
  };

  const handleDateChange = (type, event) => {
    console.log(event)
    if (type === 'startDate') {
      setEndDate('')
      setData([]);
      if(event === null){
        getTransactions('','',search,perPage)
        setStartDate('')
        showError(false)
      }else{
        setStartDate(moment(event).format('YYYY-MM-DD'));
        showError(true)
        setPagination(null)
      }

    } else {
      if(event === null){
        setEndDate('')
        showError(true)
        setData([]);
        setPagination(null)
      }else{
        setEndDate(moment(event).format('YYYY-MM-DD'));
        const endDates = moment(event).format('YYYY-MM-DD');
        showError(false)
        if (startDate !== '') {
          getTransactions(startDate, endDates,search,perPage);
        }
      }

    }
  };

  const disabledDate = current => {
    return current && current > moment();
  };

  const handleOnAddressChange = () => {};

  const handleModalType = () => {};

  const renderModal = () => {
    const title = 'Enter the shipping address to send the tag.';
    const description =
      'The shipping address is always auto-populated with the shipping address on file but you can change it if necessary.';
    const btnText = 'SHIP TAG';
    const btnType =
      'btn-style btn-success enterpriseOptionbuttons buttonWidth1';
    const shippingAddress = '1800 Lawn Lane, Austin, TX 77001';

    return (
      <Modal
        className='toll-authority'
        title={title}
        visible={visible}
        width='500px'
        footer={null}
        onCancel={() => {
          handleModalCancel();
        }}>
        {selectableObj.name && (
          <p className='text-center'>
            {selectableObj.type === 'deleteUser'
              ? `Admin: ${selectableObj.name}`
              : `User: ${selectableObj.name}`}
          </p>
        )}
        {shippingAddress && (
          <div>
            <p className='text-center'>Admin: Jones Drew</p>
            <div className='sendTagsPadding'>
              <span className='sendTagUser'>SHIPPING ADDRESS</span>

              <AddressForm
                autoComplete
                twoRows
                showReqFields
                address={address}
                onChange={(element, value, obj) =>
                  handleOnAddressChange('address', element, value, obj)
                }
              />
            </div>
          </div>
        )}
        <div className='modalDescrition'>
          <p>Disclaimer:</p>
          <p>{description}</p>
        </div>
        <Row type='flex' justify='center'>
          <ReuseButton
            text={btnText}
            className={`btn-style ${btnType}`}
            onClick={() => {
              handleModalType(selectableObj.type, 'id');
            }}
          />
        </Row>
      </Modal>
    );
  };

  return (
    <div className='Basecontainer'>
      <Spin spinning={inProgress}>
        <Row gutter={30}>
          <Col span={24} className='tablePadding  mar-top-10'>
            <p className='TitleText mar-bottom-20 '>
              Here are all of your transactions. Search transactions by dates.
            </p>
            <Row className='text-center alltransacts'>
              <Col lg={12} className='alignRight pad-rt-10'>
                Start:{' '}
                <DatePicker
                  placeholder=''
                  disabledDate={disabledDate}
                  format={'MM/DD/YYYY'}
                  onChange={e => {
                    handleDateChange('startDate', e);
                  }}
                  value={startDate !== '' ? moment(startDate) : ''}
                />
              </Col>
              <Col lg={12} className='alignLeft pad-lt-20'>
                End:{' '}
                <DatePicker
                  placeholder=''
                  format={'MM/DD/YYYY'}
                  disabledDate={d => !d || d.isAfter(moment()) || d.isBefore(startDate) }
                  disabled={startDate === ''}
                  onChange={e => {
                    handleDateChange('endDate', e);
                  }}
                  value={endDate !== '' ? moment(endDate) : ''}
                />
              </Col>

            </Row>
              {error &&  <Row ><Col style={{textAlign:'center'}} xs={24}> <span className='star-required endDateError'>Please select the end date</span> </Col></Row>}
            <Table className='BaseTable' dataSource={data} columns={columns}
            onChange={handleTableChange}
             pagination={
            pagination !== null
              ? {
                  total: parseInt(pagination.totalCount, 10),
                  current: parseInt(pagination.currentPage, 10),
                  pageSize: parseInt(pagination.perPage, 10),
                  pageSizeOptions: [5,10,20],

                }
              : {}
          }/>
          </Col>
        </Row>
        {visible && renderModal()}
      </Spin>
    </div>
  );
};

export default List;
