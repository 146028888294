import React, { useState } from 'react';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import {
  Row,
  Col,
  Table,
  Form,
  Icon,
  Button,
  Spin
} from '../../common/AntdUiComponents';
import { alertMessage } from '../../common/Common';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import AddressForm from '../../common/Addressform';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { addEnterprises } from '../../api/enterprise/enterprise';
import { validateAddressForm } from '../../common/Common';

const List = ({
  onSelectTitle,
  data,
  pagination,
  tableChange,
  loadEnterPrise,
  usersType,
  pageSizeOptions
}) => {
  const [form] = Form.useForm();
  const [showViewModal, setShowViewModal] = useState(false);
  const [companyErrors, setCompanyErrors] = useState([]);
  const [companyError, showCompanyError] = useState(false);
  const [shippingErrors, setShippingErrors] = useState([]);
  const [shippingError, showShippingError] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [addressComObj, setaddressComObj] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [addressShipObj, setaddressShipObj] = useState({});
  const [state, setState] = useState({
    name: '',
    company: '',
    shipping: '',
    billing: '',
    phone: '',
    tags: '',
  });

  const handleTitleChange = (name, id) => {
    onSelectTitle(name, 'category', id);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => {
        return (
          <span>{text && text.charAt(0).toUpperCase() + text.slice(1)}</span>
        );
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, objData) => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon
            type='arrow-right'
            onClick={() => handleTitleChange(objData.name, objData.id)}
            style={{ fontSize: '30px' }}
          />
        </span>
      ),
      align: 'right',
    },
  ];

  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const addEnterprise = () => {
    setState({
      name: '',
      company: '',
      shipping: '',
      billing: '',
      phone: '',
      tags: '',
    });
    setDisableBtn(false);
    setShowViewModal(true);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
    form.setFieldsValue({
      name: '',
      phone: '',
    });
    showCompanyError(false);
    showShippingError(false);
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    const addrressError = validateAddressForm(obj);
    const errors = [];

    if (addressType === 'company') {
      setaddressComObj(obj)
      if (addrressError) {
        errors.push(`${addrressError}`);
        setCompanyErrors(errors);
        showCompanyError(true);
      } else {
        showCompanyError(false);
      }
    } else {
      setaddressShipObj(obj)
      if (addrressError) {
        errors.push(`${addrressError}`);
        setShippingErrors(errors);
        showShippingError(true);
      } else {
        showShippingError(false);
      }
    }

    setState({
      ...state,
      [addressType]: obj,
    });
    // setaddressObj(obj)
  };

  const onFocus = (type) => {
    if (type === 'company') {
      handleOnAddressChange(type,"","",addressComObj)
    }else{
      handleOnAddressChange(type,"","",addressShipObj)
    }

  }

  const submitForm = e => {
    setInProgress(true)
    e.preventDefault();
    if (state.name.trim().length !== 0) {
      if (state.phone.length >= 11) {
        const formData = {};
        formData.city =
          state.company.city;
        formData.name = state.name.trimLeft();
        formData.state =
          state.company.state;
        formData.streetName = state.company.streetName;
        formData.streetNumber = state.company.streetNumber;
        formData.tagsCountToOrder = state.tags;
        formData.zipCode = state.company.zipcode;
        addEnterprises(true, formData).then(result => {
          if (result.success) {
            setInProgress(false)
            setDisableBtn(true);
            alertMessage(
              `${state.name.toUpperCase()} HAS BEEN ADDED!`,
              'success',
              3
            );
            setShowViewModal(false);
            loadEnterPrise();
            form.setFieldsValue({
              name: '',
              phone: '',
            });
          } else {
            setInProgress(false)
            setDisableBtn(false);
            if (Array.isArray(result.errors.message.message)) {
              alertMessage(
                result.errors.message.message.toString().toUpperCase(),
                'error',
                3
              );
            } else {
              setDisableBtn(false);
              alertMessage(
                result.errors.message.message.toUpperCase(),
                'error',
                3
              );
            }
          }
        });
      } else {
        setInProgress(false)
        alertMessage('PLEASE ENTER A CORRECT PHONE NUMBER', 'error', 3);
      }
    } else {
      setInProgress(false)
      alertMessage('PLEASE PROVIDE A VALID COMPANY NAME', 'error', 3);
    }
  };

  const renderViewModel = () => {
    return (
      <BaseModal width='70%' onCancel={() => handleOnModalClose()}>
            <Spin spinning={inProgress}>
        <Form layout='vertical' form={form}>
          <div className='addtolls addEnts'>
            <Row className='WrapperPads' gutter={20}>
              <Col span={10}>
                <Form.Item
                  name='name'
                  validateTrigger='onBlur'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a name!',
                      validationTrigger: 'onBlur',
                    },
                    () => ({
                      validator(rule, value) {
                        if (value===undefined) {
                          return Promise.resolve();
                        }
                        if ((value.length===0)&& value.trim() === '') {
                          return Promise.resolve();
                        }

                        if((value.length>0)&& value.trim() !== ''){
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Please enter a valid name.')
                        );
                      },
                    }),
                  ]}
                  label='COMPANY NAME'
                  className='authlabels'>
                  <ReuseInputField
                    className='TagInput'
                    width='100%'
                    placeholder='Company Name'
                    value={state.name}
                    onChange={handleChange('name')}
                  />
                </Form.Item>
              </Col>
              <Col
                offset={4}
                span={10}
                className={`${companyError ? 'errorAddress' : ''}`}>
                <span className='TagModalTitle'>
                  COMPANY ADDRESS <span className='star-required'>*</span>
                </span>
                <br />
                <AddressForm
                  autoComplete
                  twoRows
                  showReqFields
                  address={state.company}
                   onFocus={()=>onFocus('company')}
                  onChange={(element, value, obj) =>
                    handleOnAddressChange('company', element, value, obj)
                  }
                />
                {companyError && (
                  <span className='errorMessageAddress'>{companyErrors} in company address</span>
                )}
              </Col>
            </Row>
            <Row style={{ paddingBottom: '5%' }} gutter={20}>
              <Col
                span={10}
                className={`${shippingError ? 'errorAddress' : ''}`}>
                <span className='TagModalTitle'>
                  SHIPPING ADDRESS <span className='star-required'>*</span>
                </span>
                <br />

                <AddressForm
                  autoComplete
                  twoRows
                  showReqFields
                  onFocus={()=>onFocus('shipping')}
                  address={state.shipping}
                  onChange={(element, value, obj) =>
                    handleOnAddressChange('shipping', element, value, obj)
                  }
                />
                {shippingError && (
                  <span className='errorMessageAddress'>{shippingErrors} in shipping address</span>
                )}
              </Col>
              <Col offset={4} span={10}>
                <Form.Item
                  name='phone'
                  validateTrigger='onBlur'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a contact number!',
                      validationTrigger: 'onBlur',
                    },
                    () => ({
                      validator(rule, value) {
                        console.log(value)
                        if(value === undefined || value.length===11 || value.length===12){
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Please enter a valid phone number.')
                        );
                      },
                    }),
                  ]}
                  label='PHONE NUMBER (Primary contact)'
                  className='authlabels'>
                  <PhoneInput
                    country='us'
                    onlyCountries={['us', 'in']}
                    specialLabel=''
                    countryCodeEditable
                    value={state.phone}
                    onChange={handleChange('phone')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ paddingBottom: '5%' }} gutter={20}>
              <Col span={10}>
                <span className='TagModalTitle'>NUMBER OF TAGS TO ORDER</span>
                <br />
                <ReuseInputField
                  className='TagInput'
                  width='100%'
                  type='number'
                  min={0}
                  placeholder='No. of Tags'
                  value={state.tags}
                  onChange={handleChange('tags')}
                />
              </Col>
              <Col offset={4} span={10}></Col>
            </Row>
          </div>
          <div className='createWrapper'>
            <ReuseButton
              text='CREATE'
              className={
                state === {} ||
                state.name === '' ||
                state.shipping === '' ||
                companyError ||
                shippingError ||
                state.company === '' ||
                state.phone === ''
                  ? 'CreateTag'
                  : 'AddCreateTag'
              }
              onClick={submitForm}
              disabled={
                state === {} ||
                state.name.trim() === '' ||
                state.shipping === '' ||
                companyError ||
                shippingError ||
                state.company === '' ||
                state.phone === '' ||
                state.phone.length<11||
                disableBtn
              }
            />
          </div>
        </Form>
        </Spin>
      </BaseModal>
    );
  };

  return (
    <div className='Basecontainer'>
      <Row gutter={30}>
        <Col span={24} className='tablePadding mar-top-20'>
          <p className='TitleText mar-bottom-20'>
            Manage all of your enterprise customers here.
          </p>
          <p className='TitleText mar-bottom-20 addTollAuthBtn'>
            <Button className='AddButton' onClick={addEnterprise}>
              ADD ENTERPRISE
            </Button>
          </p>
          <Table
            className='BaseTable userCategories'
            dataSource={data}
            columns={columns}
            showHeader={false}
            pagination={pagination}
            onChange={tableChange}
            pageSizeOptions={pageSizeOptions}
            onRow={record => {
              return {
                onClick: () => {
                  handleTitleChange(record.name, record.id);
                },
              };
            }}
          />
        </Col>
      </Row>
      {showViewModal && renderViewModel()}
    </div>
  );
};

export default List;
