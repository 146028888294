/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import List from '../components/orderedTags/List';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../utils/reusableComponents/ReuseButton';
import BaseModal from '../utils/reusableComponents/BaseModal';
import { Row, Col, AutoComplete,Spin,Form } from '../common/AntdUiComponents';
import AddressForm from '../common/Addressform';
import { alertMessage } from '../common/Common';
import { fetchEnterprices } from '../api/enterprise/enterprise';
import { orderTag, getOrderedTags } from '../api/tags';
import {validateAddressForm,sorters} from '../common/Common'
import AppConfig from '../config/AppConfig';

const { Option } = AutoComplete;
const user = localStorage.getItem('user');
const userDetails = user!==''? JSON.parse(user) : { };

const OrderedTags = () => {
  const [title, setTitle] = useState('Status of Ordered Tags');
  const [showViewModal, setShowViewModal] = useState(false);
  const [result, setResult] = useState([]);
  const [enterprisesData, setEnterprisesData] = useState([]);
  const [ParentAccount, setParentAccount] = useState('');
  const [userData, setUserData] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [address, setAddress] = useState('');
  const [pagination, setPagination] = useState({});
  const [status, setStatus] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [perPage,setPerPage] = useState(20)
  const [sortKey,setSortKey] = useState('')
  const [sortOrder,setSortOrder] = useState('')

  const [form] = Form.useForm();

  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const getAllOrderedTags = (value,tagStatus,pageCount,sortkey,sorter) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    getOrderedTags(value,page,pageCount,tagStatus,sortkey,sorter).then((result) => {
      if (result.success) {
        setUserData(result.records);
        setPagination(result.pagination);
        setInProgress(false);
        // setUserData(result.records);
      } else {
        setUserData([]);
        setPagination(null);
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    getAllOrderedTags('',status,AppConfig.perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination)
    console.log(filters)
    console.log(sorter)
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize)
    let sorterOrder='';
    let sorterkey='';
    sorter.field = sorter.field === 'tagIdentificationNumber' ? 'tagId':sorter.field
    console.log(sorters(sorter.order))
        sorterOrder = sorters(sorter.order);
      sorterkey = sorters(sorter.order)===''?'': sorter.field;
       setSortKey(sorters(sorter.order)===''?'': sorter.field);
       setSortOrder(sorters(sorter.order))
    getAllOrderedTags(searchValue,status,pagination.pageSize,sorterkey,sorterOrder);
  };

  const handleTitle = (langValue) => {
    setTitle(langValue);
  };

  const handlebySearch = (value) => {
    setSearchValue(value)
    getAllOrderedTags(value,status,perPage)
  }

  const handleSubmit = (e) => {
    setEnterprisesData([])
    e.preventDefault();
    setDisableBtn(false)
    setShowViewModal(true);
    setAddress('')
    form.setFieldsValue({
      account: '',
    });
  };

  const handleClick = (e) => {
    if (e.target.value === '') {
      setEnterprisesData([]);
    }
  };

  const handleSearch = (value) => {
    const res = [];
    if (value === '') {
      setEnterprisesData([]);
      setParentAccount('');
      setEnterprisesData([]);
      setAddress('')
    } else {
      setParentAccount(value);
      // eslint-disable-next-line no-shadow
      fetchEnterprices(1, 1000).then((result) => {
        if (result.success) {
          const entData = result.data.records.filter((rec) =>
            rec.name.toLowerCase().includes(value.toLowerCase()),
          );
          setEnterprisesData(entData);
        } else {
          alertMessage(
            result.errors.message.message.toUpperCase(),
            'error',
            3
          );
        }
      });

      setResult(res);
    }
  };

  const onFocus = (event,newAddress) => {
    if(newAddress===''||newAddress===undefined){
      console.log(address)
      if(address!==''){
      address.streetNumber =  address.address_line1!==undefined?address.address_line1:'';
      address.streetName =  address.address_line2!==undefined?address.address_line2:'';
      }
      const addrressError = validateAddressForm(address);
      if(addrressError){
        const errors = [];
        errors.push(`${addrressError}`);
        setErrors(errors)
        showError(true)
      }else{
        showError(false)
      }
      handleOnAddressChange('','','',address);
    }else{
      if(newAddress!==''){
        newAddress.streetNumber =  newAddress.address_line1!==undefined?newAddress.address_line1:'';
        newAddress.streetName =  newAddress.address_line2!==undefined?newAddress.address_line2:'';
        }
        const addrressError = validateAddressForm(newAddress);
        if(addrressError){
          const errors = [];
          errors.push(`${addrressError}`);
          setErrors(errors)
          showError(true)
        }else{
          showError(false)
        }
    }

  }

  const handleOrder = (e) => {
    setDisableBtn(true)
    e.preventDefault();
    const formData = {};
    formData.enterpriseName = ParentAccount;
    // eslint-disable-next-line no-shadow
    orderTag(formData).then((result) => {
      if (result.success) {
        setShowViewModal(false);
        setParentAccount('');
        setAddress('');
        alertMessage('A TAG HAS BEEN ORDERED!', 'success', 3);
      } else {
        setShowViewModal(false);
        setParentAccount('');
        setAddress('');
        if(Array.isArray(result.errors.message.message)){
          alertMessage(result.errors.message.message.toString().toUpperCase(), 'error', 3);
        }
        else{
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      }
    });
  };

  const onSelect = (value, _result) => {
    setParentAccount(value);
    fetchEnterprices(1, 5, value).then((result) => {
      if (result.success) {
        if (result.data.records !== null) {
         const entData = result.data.records[0];
        const address = {
          address_line1: entData.streetNumber,
          address_line2: entData.streetName,
          state:entData.state,
          city:entData.city,
          zipcode: entData.zipCode,
          country: 'US',
        };
        setAddress(address)
        onFocus("",address)
        } else {
          setEnterprisesData([]);
        }
      } else {
        setEnterprisesData([]);
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
      }
    });

  };

  const checkStatus  = (tagstatus) => {
   setStatus(tagstatus)
  getAllOrderedTags(searchValue,tagstatus,perPage,sortKey,sortOrder)
  }

  const handleOnAddressChange = (
    addressType,
    element,
    value,
    obj,
    addressLine,
  ) => {

    setAddress(obj);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
    setErrors([])
    showError(false)

  };

  const renderViewModel = () => {
    return (
      <BaseModal width="65%" onCancel={() => handleOnModalClose()}>
            <Form layout='vertical' form={form}>
        <Row className="createTagsOrdered" gutter={20}>
          <Col span={12}>
            {/* <span className="TagModalTitle">PARENT ACCOUNT <span className='star-required'>*</span></span>
            <br /> */}
            <Form.Item
              name='account'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please select an account!',
                  validationTrigger: 'onBlur',
                },
              ]}
              label='PARENT ACCOUNT'
              className='authlabels'>
            <AutoComplete
              style={{
                width: '380px',
              }}
              onSelect={(value) => onSelect(value, result)}
              onSearch={handleSearch}
              onClick={handleClick}
              placeholder="Search Account"
            >
              {enterprisesData.map((data) => (
                <Option key={data.id} value={data.name}>
                  {data.name}
                </Option>
              ))}
            </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={12}    className={`${error ? 'errorAddress' : ''}`}>
            <span className="TagModalTitle">SHIPPING ADDRESS <span className='star-required'>*</span></span>
            <AddressForm
              autoComplete
              twoRows
              showReqFields
              onFocus={onFocus}
              address={address}
              onChange={(element, value, obj, addressLine) =>
                handleOnAddressChange(
                  'address',
                  element,
                  value,
                  obj,
                  addressLine,
                )
              }
            />
              {error && <span className='errorMessageAddress'>{errors} in shipping address</span>}
          </Col>
        </Row>
        <Row />
        <div className="createWrapper">
          <ReuseButton
            text="ORDER"
            className={
              ParentAccount === '' || address === ''
                ? 'CreateTag'
                : 'AddCreateTag'
            }
            disabled={ParentAccount === '' || address === ''|| disableBtn ||error}
            onClick={(e) => {
              handleOrder(e);
            }}
          />
        </div>
        </Form>
      </BaseModal>
    );
  };

  return (
    <div>
       <Spin spinning={inProgress}>
      <p className="ContainerTitleHeader">
        <Row>
          <Col lg={10}>
            {' '}
  <span className="PageTitle">{title} ({pagination!==null && (pagination.totalCount)})</span>
          </Col>
          <Col lg={14} className="alignRight">
            {userDetails && userDetails.userType === 'UNYTAG' &&  <ReuseButton
              text="MANUALLY ORDER TAG"
              className="AddOrderTag"
              onClick={(e) => {
                handleSubmit(e);
              }}
            />}
{' '}
            <ReuseInputField
              placeholder="Search by address, number.."
              className="SearchBarOrdered"
              width="800px"
              onChange={handlebySearch}
            />
          </Col>
        </Row>
      </p>
      <List
        onSelectTitle={handleTitle}
        data={userData}
        sendStatus={checkStatus}
        pagination={
          pagination !== null
            ? {
                total: parseInt(pagination.totalCount, 10),
                current: parseInt(pagination.currentPage, 10),
                pageSize: parseInt(pagination.perPage, 10),
                pageSizeOptions: [5,10,20],
              }
            : {}
        }
        // eslint-disable-next-line no-shadow
        tableChange={(pagination, filters, sorter) =>
          handleTableChange(pagination, filters, sorter)
        }
      />
      {showViewModal && renderViewModel()}
      </Spin>
    </div>
  );
};

export default OrderedTags;
