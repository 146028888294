/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import {
  Row,
  Col,
  Table,
  Icon,
  Button,
  Upload,
} from '../../common/AntdUiComponents';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';

let fileObj = {};
let files = [];

const TollFacities = ({ onSelectTitle }) => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [fileName, setfileName] = useState('');

  const dataSource = [
    {
      key: '1',
      name: 'Fort Bend Parkway',
      age: 32,
    },
    {
      key: '2',
      name: `Grand Parkway`,
      age: 42,
    },
    {
      key: '3',
      name: `Grand Bay`,
      age: 42,
    },
    {
      key: '4',
      name: `Fort Aguada`,
      age: 42,
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, data) => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon type="arrow-right" style={{ fontSize: '30px' }} />
        </span>
      ),
      align: 'right',
    },
  ];

  const handleTitleChange = (name) => {
    onSelectTitle(name, 'plazas');
  };

  const addTollFacility = (e) => {
    e.preventDefault();
    setShowViewModal(true);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
  };

  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      setfileName(info.file.name);
      fileObj = info.fileList[0].originFileObj;
      files = info.fileList;
    }
  };

  const renderViewModel = () => {
    return (
      <BaseModal
        width="550px"
        onCancel={() => handleOnModalClose()}
        style={{ textAlign: 'center' }}
      >
        <Col lg={24} className="uplaodForm">
          <Upload
            showUploadList={false}
            onChange={onChange}
            beforeUpload={(file) => {
              const reader = new FileReader();

              reader.onload = (e) => {};
              reader.readAsText(file);
              return false;
            }}
          >
            <Col lg={24} className="password">
              UPLOAD EXCEL DOC
            </Col>
            <ReuseInputField
              type="text"
              placeholder=""
              height="52px"
              width="59%"
              value={fileName}
            />
            <Button text="BROWSE" className="center1auth uploadFacility">
              BROWSE
            </Button>
          </Upload>

          <Col lg={24} className="uploadContainer">
            <ReuseButton text="UPLOAD" className="center1upload" />
          </Col>
        </Col>
      </BaseModal>
    );
  };

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding  mar-top-20">
          <p className="TitleText mar-bottom-20">
            Here are the facilities asscoiated with this toll authority.
          </p>
          <p className="TitleText mar-bottom-20 addTollFacBtn">
            <Button className="AddButton tollfP" onClick={addTollFacility}>
              ADD TOLL FACILITY
            </Button>
          </p>

          <Table
            className="BaseTable userCategories"
            dataSource={dataSource}
            columns={columns}
            showHeader={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleTitleChange(record.name);
                },
              };
            }}
          />
        </Col>
      </Row>
      {showViewModal && renderViewModel()}
    </div>
  );
};

export default TollFacities;
