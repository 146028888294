/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Table,
  Modal,
  message,
  Button,
  Upload,
} from '../../common/AntdUiComponents';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';

let fileObj = {};
let files = [];

const Plazas = ({ onSelectTitle }) => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [fileName, setfileName] = useState('');
  const dataSource = [
    {
      key: 0,
      name: 'Fondren',
      age: 32,
    },
    {
      key: 1,
      name: `Lake Olympia`,
      age: 42,
    },
    {
      key: 2,
      name: `McHard`,
      age: 42,
    },
    {
      key: 3,
      name: `Sienna Parkway`,
      age: 42,
    },
  ];
  const [visible, setVisible] = useState(false);
  const [removeObj, setRemoveObj] = useState(0);
  const [data, setData] = useState(dataSource);

  const handleSubmit = (e, obj) => {
    e.preventDefault();
    setVisible(!visible);
    setRemoveObj(obj);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, objData) => {
        return (
          <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
            <ReuseButton
              text="REMOVE"
              className="btn-style btn-danger buttonWidth1"
              onClick={(e) => {
                handleSubmit(e, objData);
              }}
            />
          </span>
        );
      },
      align: 'right',
    },
  ];

  const remove = () => {
    setVisible(!visible);
    const arr = _.remove(data, (d) => {
      return d.key !== removeObj.key;
    });
    setData(arr);
    message.success({
      content: `${removeObj.name} HAS BEEN REMOVED!`,
      className: 'bg-maroon',
      duration: 5,
    });
  };

  const renderModal = () => (
    <Modal
      className="toll-authority"
      title="Do you want to remove the plaza?"
      visible={visible}
      footer={null}
      onCancel={handleSubmit}
    >
      <p className="text-center">{removeObj.name}</p>
      <div className="modalDescrition">
        <p>Disclaimer:</p>
        <p>
          If you remove the plaza all users will no longer be able to use
          Unytage for this specific plaza.
        </p>
      </div>
      <Row type="flex" justify="center">
        <ReuseButton
          text="REMOVE"
          className="btn-style btn-danger buttonWidth1"
          onClick={remove}
        />
      </Row>
    </Modal>
  );

  const addTollPlaza = (e) => {
    e.preventDefault();
    setShowViewModal(true);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
  };

  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      setfileName(info.file.name);
      fileObj = info.fileList[0].originFileObj;
      files = info.fileList;
    }
  };

  const renderViewModel = () => {
    return (
      <BaseModal
        width="550px"
        onCancel={() => handleOnModalClose()}
        style={{ textAlign: 'center' }}
      >
        <Col lg={24} className="uplaodForm">
          <Upload
            showUploadList={false}
            onChange={onChange}
            beforeUpload={(file) => {
              const reader = new FileReader();

              reader.onload = (e) => {};
              reader.readAsText(file);
              return false;
            }}
          >
            <Col lg={24} className="password">
              UPLOAD EXCEL DOC
            </Col>
            <ReuseInputField
              type="text"
              placeholder=""
              // icon="Mail"
              height="52px"
              width="59%"
              value={fileName}
            />
            <Button text="BROWSE" className="center1auth uploadFacility">
              BROWSE
            </Button>
          </Upload>

          <Col lg={24} className="uploadContainer">
            <ReuseButton text="UPLOAD" className="center1upload" />
          </Col>
        </Col>
      </BaseModal>
    );
  };

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding  mar-top-20">
          <p className="TitleText mar-bottom-20">
            Here are the toll plazas associated with this facility.
          </p>
          <p className="TitleText mar-bottom-20 addTollFacBtn">
            <Button className="AddButton tollfP" onClick={addTollPlaza}>
              ADD TOLL PLAZA
            </Button>
          </p>
          <Table
            className="BaseTable"
            dataSource={data}
            columns={columns}
            showHeader={false}
          />
        </Col>
      </Row>
      {visible && renderModal()}
      {showViewModal && renderViewModel()}
    </div>
  );
};

export default Plazas;
