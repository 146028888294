import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Icon } from '../../common/AntdUiComponents';

const CategoryList = ({ onSelectTitle, entName, dataValue }) => {
  const dataSource = [
    {
      key: '1',
      name: 'Users',
      age: 32,
    },
    {
      key: '2',
      name: `Trip History & Statements`,
      age: 42,
    },
    {
      key: '3',
      name: 'Payment Method',
      age: 42,
    },
    {
      key: '4',
      name: 'Profile',
      age: 42,
    },
  ];

  const [data, setData] = useState([]);

  const handleTitleChange = (name, nextState) => {
    onSelectTitle(name, nextState);
  };

  useEffect(() => {
    if (dataValue !== '') {
      const filteredData = dataSource.filter(
        (datas) => datas.name.toLowerCase().includes(dataValue.toLowerCase()),
      );
      setData(filteredData);
    } else {
      setData(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, data) => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon
            type="arrow-right"
            style={{ fontSize: '30px' }}
            onClick={() => handleTitleChange(entName, data.name)}
          />
        </span>
      ),
      align: 'right',
    },
  ];

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding mar-top-10">
          <p className="TitleText mar-bottom-20">
            View everything that has happened with the enterprise customer here.
          </p>
          <Table
            className="BaseTable userCategories"
            dataSource={data}
            pagination={false}
            columns={columns}
            showHeader={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleTitleChange(entName, record.name);
                },
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CategoryList;
