import Enterprises from '../containers/Enterprises';
import UserAccounts from '../components/enterprises/EnterpriseInfo';
import EnterpriseInfo from '../components/enterprises/EnterpriseInfo';
import EnterpriseUserForm from '../components/enterprises/EnterPriseUserForm';
import Overview from '../containers/Overview';
import Tags from '../containers/Tags';
import Users from '../containers/Users';
import UserInfo from '../components/users/UserInfo';
import TollAuthority from '../containers/TollAuthority';
import TollAuthorityInfo from '../components/tollAuthority/TollAuthorityInfo';
import OrderedTags from '../containers/OrderedTags';
import PaymentIssues from '../containers/PaymentIssues';
import AddUser from '../components/enterprises/AddUser';
import DefectiveTags from '../containers/DefectiveTags';
import AllTransactions from '../containers/AllTransactions';
import Support from '../containers/Support';
import Invite from '../containers/Invite';

const AppRoutes = [
  {
    key: '000',
    exact: true,
    path: '/unytag/web/overview',
    name: 'Overview',
    component: Overview,
  },
  {
    key: '001',
    exact: true,
    path: '/unytag/web/enterprise',
    name: 'Enterprises',
    component: Enterprises,
  },
  {
    key: '002',
    exact: true,
    path: '/unytag/web/tags',
    name: 'Tags',
    component: Tags,
  },
  {
    key: '003',
    exact: true,
    path: '/unytag/web/users',
    name: 'Tags',
    component: Users,
  },
  {
    key: '004',
    exact: true,
    path: '/unytag/web/',
    name: 'Overview',
    component: Overview,
  },
  {
    key: '005',
    exact: true,
    path: '/unytag/web/tollauthority',
    name: 'Toll Authority',
    component: TollAuthority,
  },
  {
    key: '006',
    exact: true,
    path: '/unytag/web/orderedtags',
    name: 'Ordered Tags',
    component: OrderedTags,
  },
  {
    key: '007',
    exact: true,
    path: '/unytag/web/tollauthority/:name/:id',
    name: 'Toll Authority',
    component: TollAuthorityInfo,
  },
  {
    key: '008',
    exact: true,
    path: '/unytag/web/enterprise/:name/:id',
    name: 'Toll Authority',
    component: EnterpriseInfo,
  },
  {
    key: '009',
    exact: true,
    path: '/unytag/web/addEnterprise/:type',
    name: 'Add New User',
    component: AddUser,
  },
  {
    key: '010',
    exact: true,
    path: '/unytag/web/addEnterprise/:type/:name/:entName/:id',
    name: 'Add New User',
    component: EnterpriseUserForm,
  },
  {
    key: '011',
    exact: true,
    path: '/unytag/web/users/:name/:id',
    name: 'User Info',
    component: UserInfo,
  },
  {
    key: '011',
    exact: true,
    path: '/unytag/web/paymentIssues',
    name: 'User Info',
    component: PaymentIssues,
  },
  {
    key: '012',
    exact: true,
    path: '/unytag/web/defectiveTags',
    name: 'User DefectiveTags',
    component: DefectiveTags,
  },
  {
    key: '013',
    exact: true,
    path: '/unytag/web/allTransactions',
    name: 'All Transactions',
    component: AllTransactions,
  },
  {
    key: '014',
    exact: true,
    path: '/unytag/web/support',
    name: 'Support',
    component: Support,
  },
  {
    key: '015',
    exact: true,
    path: '/invite',
    name: 'Invitation',
    component: Invite,
  },
  {
    key: '016',
    exact: true,
    path: '/unytag/web/accounts/:name/:id',
    name: 'Enterprises',
    component: UserAccounts,
  },
  {
    key: '017',
    exact: true,
    path: '/unytag/web/enterprise/:name/:id/:payment',
    name: 'Toll Authority',
    component: EnterpriseInfo,
  },
];
export default AppRoutes;
