import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Test from './components/Test';
import ForgetPassword from './components/auth/ForgetPassword';
import ResetPassword from './components/auth/ResetPassword';
import Invite from './containers/Invite';

const stripePromise = loadStripe(
  'pk_test_51HieW1HryA5A4cqs1BA6JRCqHOCq35KepKQxHA126DmalUHoh28IyoYRiY1mCS4Dn7vflCTFerl4Bj6ThrHdlNct00wjkvHrSP',
);

const isAuth = localStorage.getItem('token'); // i will fetch stored token
ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Router>
        <Switch>
          <Route exact path="/unytag/web/login" component={App} />
          <Route exact path="/unytag/web/logout" component={Test} />
          <Route
            exact
            path="/unytag/web/forgot_password"
            component={ForgetPassword}
          />
          <Route exact path="/unytag/web/user/updatepassword" component={ResetPassword} />
          <Route exact path="/unytag/web/invite" component={Invite} />
          <Route path="/unytag/web/" component={App} />
          {isAuth ? (
            <Redirect from="/" to="/unytag/web/overview" />
          ) : (
            <Redirect from="/" to="/unytag/web/" />
          )}
        </Switch>
      </Router>
    </Elements>{' '}
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
