/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import _ from 'lodash';
import { AutoComplete } from 'antd';
import { Col, Row } from './AntdUiComponents';

Geocode.setApiKey('AIzaSyCNNmfTGsBatXy77JEAcjxuHCR2WSxVhvg');
Geocode.enableDebug();
let postalCode = '';
class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
      autoAddress: '',
      disabled: props.disabled,
      defaultValue: props.address,
    };
    this.getSuggestions = _.debounce(this.getSuggestions, 500);
  }

  renderAddressLabel = (attr) => `general.${attr}`;

  handleSelect = () => {};

  getAddress = (obj) => {
    const address = obj.description;
    this.setState({ defaultValue: address });
    const output = {
      country: '',
      state: '',
      city: '',
      address_line2: '',
      address_line1: '',
      lat: '',
      lng: '',
      zipcode: '',
    };

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        Geocode.fromLatLng(latLng.lat, latLng.lng).then(
          (response) => {
            const Zipaddress = response.results[0].formatted_address;
            const splicedZipaddress = Zipaddress.split(',').splice(-2);
            postalCode = splicedZipaddress[0].split(' ').splice(-1);

            this.formatAddress(address, output, latLng);
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {},
        );
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {});
  };

  formatAddress = (address, outputs, latLng) => {
    let addressArray = address.split(',');
    const output = outputs;
    const address_line1 = '';
    addressArray = address.split(',').reverse();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < addressArray.length; i++) {
      output[Object.keys(output)[i]] = addressArray[i];
    }
    output.address_line1 =
      addressArray.length > 4
        ? ` ${addressArray.slice(4).reverse().join(', ')}`
        : address_line1;

    output.lat = latLng.lat;
    output.lng = latLng.lng;

    output.streetName =  addressArray.length <=4?'':output.address_line2;
    output.streetNumber = addressArray.length <=4 ? output.address_line2 : output.address_line1;
    output.zipcode = postalCode.toString();
    console.log(output)
    this.handleOnAddressChange('', '', output);
  };

  componentWillMount() {
    this.getInitAddress();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ address: nextProps.address }, () => {
      if (Object.keys(nextProps.address).length > 0) {
        this.setState({
          defaultValue: this.addressObjectToString(nextProps.address),
        });
      } else {
        this.setState({ defaultValue: '' });
      }
    });
  }

  onSelect = (id, suggestions) => {
    const address = suggestions.filter((x) => x.placeId === id);
    if (address.length) {
      this.getAddress(address[0]);
    }
  };

  getSuggestions = (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then(() => this.handleOnAddressChange('latLng', value))
      .catch((error) => console.error('Error', error));
  };

  handleChange = (autoAddress) => {
    this.setState({ autoAddress, defaultValue: autoAddress }, () => {
      if (autoAddress === '') this.handleOnAddressChange('', '', '');
    });
  };

  getInitAddress = () => {
    this.setState({ address: this.props.address });
    this.setState({ address: this.props.address }, () => {
      if (Object.keys(this.props.address).length > 0) {
        this.setState({
          defaultValue: this.addressObjectToString(this.props.address),
        });
      } else {
        this.setState({ defaultValue: '' });
      }
    });
  };

  handleOnAddressChange(element, value, obj) {
    this.props.onChange(element, value, obj, this.state.defaultValue);
  }

  addressObjectToString = (address) => {
    let addressText = '';
    if (Object.keys(address).length > 0) {
      const displayAddress = (({
        address_line1,
        address_line2,
        city,
        state,
        country,
        zipcode,
      }) => ({
        address_line1,
        address_line2,
        city,
        state,
        country,
        zipcode,
      }))(address);

      const filtered = Object.values(displayAddress).filter(
        (x) => x !== '' && x !== null,
      );
      addressText = filtered.join(', ');
    } else {
      addressText = '';
    }
    return addressText;
  };

  setDefaultValue = () => {};

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.autoAddress}
        onChange={this.handleChange}
        onClick={this.handleChange}
        onSelect={this.handleSelect}
        debounce={1000}
        setDefaultValue={this.setDefaultValue}
      >
        {({ getInputProps, suggestions }) => (
          <div>
            {this.props.autoComplete ? (
              <Row>
                <Col xs={24}>
                  <AutoComplete
                    style={{ width: '100%', marginBottom: 20 }}
                    disabled={this.props.disabled}
                    placeholder="Search address here..."
                    dataSource={suggestions.map((item) => ({
                      value: item.placeId,
                      text: item.description,
                    }))}
                    onSelect={(value) => this.onSelect(value, suggestions)}
                    onBlur={this.props.onFocus}
                    onSearch={(value) =>
                      getInputProps().onChange({
                        target: {
                          value,
                        },
                      })
                    }
                    onChange={(value) =>
                      getInputProps().onChange({
                        target: {
                          value,
                        },
                      })
                    }
                    value={this.state.defaultValue}
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

AddressForm.defaultProps = {
  address: {},
  disabled: false,
  autoComplete: false,
  twoRows: false,
};

export default AddressForm;
