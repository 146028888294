import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { Row, Col, Form, Spin, Select } from 'antd';
import logo1 from '../../images/Unytaglogo.png';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { forgotPassword ,userLogin} from '../../api/login';
import { mapValues } from 'lodash';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ForgetPassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      inProgress: false,
      disableBtn: false,
      showError: false,
      errorMessage: '',
      default: false,
      companyId:'',
      user: {},
      users: [],
    };
  }

  componentDidMount() {}

  handleChange = key => value => {
    console.log(value)
    this.setState({ [key]: value });
    const formData = {};
    formData.email = value
    userLogin(formData).then(result => {
      if (result.success) {
	console.log(result.data);
        if (result.data.length > 0) {
          this.setState({ users: result.data }, () => {
	console.log(this.state.users)
            if (this.state.users.length >0) {

              this.setState(
                {
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  user: this.state.users[0],
                  default: true,
                  errorMessage: '',
                  showError: false,
                },
                () => {
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  this.setState({ companyId: this.state.users[0].companyId });
                  this.formRef.current.setFieldsValue({
                    company: this.state.users[0].companyId,
                  });
                }
              );

            }
          });
        } else {
          this.setState(
            {
              users: [],
              errorMessage: 'Please enter a registered email',
              showError: true,
            },
            () => {
              this.setState({ user: {}, default: false }, () => {
                this.setState({ company: '' });
                this.formRef.current.setFieldsValue({
                  company: '',
                });
              });
            }
          );
        }
      } else {
        this.setState({
          users: [],
        });
      }
    });
  };

  handleCompanyChange = (e, value) => {
    const { users } = this.state;
    console.log(e, value)
    this.setState({ [e]: value });
    const user = users.filter(usr => usr.companyId === value);
    this.setState({ user: user[0] });
  };

  hasErrors = fieldsError =>
    Object.keys(fieldsError).some(field => fieldsError[field]);

  handleOnChange = (element, value) => {
    const { form } = this.props;
    form.setFieldsValue({
      [element]: value,
    });
  };

  handleSubmit = () => {
    const { history } = this.props;
    this.setState({ inProgress: true });
    const formData = {};
    formData.companyId = this.state.companyId;
    formData.email = this.state.email;
    formData.userId = this.state.user.id
    forgotPassword(formData).then(result => {
      if (result.success) {
        this.setState({ inProgress: false, disableBtn: true });
        swal({
          title: 'Success!',
          text: ` We've sent an email to you.
            Click the link in the email to reset your password.`,
          icon: 'success',
          button: 'OK',
        }).then(() => {
          history.push('/unytag/web');
        });
      } else {
        this.setState({ inProgress: false, disableBtn: false });
        swal('Oops!', result.errors.message.message, 'error');
      }
    });
  };

  render() {
    const { email, inProgress } = this.state;
    return (
      <div className='LoginLayout'>
        <Spin spinning={inProgress}>
          <div className='LoginContentSize'>
            <Row className='forgetContent'>
              <Col lg={3} className='logofPass'>
                <Link to='/unytag/web'>
                  <img
                    src={logo1}
                    alt='logo'
                    style={{ height: '18px', width: '30px' }}
                  />
                </Link>
              </Col>
              <Col lg={14} style={{ margin: 'auto auto', textAlign: 'center' }}>
                <Col lg={15} offset={6} className='ftPassword'>
                  <p className='forgotNote'>Forgot your password?</p>
                  <p className='forgothelpText'>
                    We’ll help you reset it and get back on track.
                  </p>

                  <Form onSubmit={this.handleSubmit} layout='vertical' ref={this.formRef}>

                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please provide your email address',
                        },
                        {
                          type: 'email',
                          message: 'Please provide a valid email address',
                          validationTrigger: 'onBlur',
                        },
                      ]}
                      label='WORK EMAIL ADDRESS'
                      className='authlabels'>
                      <ReuseInputField
                        type='text'
                        className={`${this.state.showError?'textboxErrror':''}`}
                        placeholder='john@mainstreet.com'
                        height='52px'
                        value={email}
                        onChange={this.handleChange('email')}
                      />
                    </Form.Item>

                    {this.state.showError &&
                    re.test(String(email).toLowerCase()) && (
                      <p className='errorMessageFor'>
                        <span>{this.state.errorMessage}</span>
                      </p>
                    )}

<Form.Item
                      name='company'
                      rules={[
                        { required: true, message: 'Please select a company' },
                      ]}
                      label='COMPANY'
                      className='authlabels'>
                      <Select
                        placeholder='Select one'
                        value={this.state.company}
                        showSearch
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={e => this.handleCompanyChange('companyId', e)}>
                        {this.state.users.map(usr => (
                          <Select.Option
                            key={usr.id}
                            value={usr.companyId}>
                            {usr.company}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <br />
                    <ReuseButton
                      text='RESET PASSWORD'
                      disabled={
                        email === '' ||
                        !re.test(
                          String(email).toLowerCase()
                        ) || this.state.disableBtn || this.state.errorMessage
                      }
                      htmlType='submit'
                      className='center1auth'
                      onClick={this.handleSubmit}
                    />
                  </Form>
                </Col>
                <Col lg={6} />
              </Col>
              <Col lg={5} />
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

export default withRouter(ForgetPassword);
