/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Input,
  Spin,
} from '../common/AntdUiComponents';
import ReuseButton from '../utils/reusableComponents/ReuseButton';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import {
  freezeAccount,deleteEnterpriceUserById
} from '../api/enterprise/enterprise';
import {
  paymentIssues
} from '../api/overview';
import { alertMessage } from '../common/Common';

const user = localStorage.getItem('user');
const userDetails = user!==''? JSON.parse(user) : { };

const PaymentIssues = ({ onSelectTitle, name, tableChange, id }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectableObj, setSelectableObj] = useState('');
  const [enterPrise, setEnterprise] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [pagination, setPagination] = useState({});
  const [userData, setuserData] = useState([{ id: 1 }]);

  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const getPaymentIssues = () => {
    setInProgress(true);

    const page = tableOptions.pagination.current;

    paymentIssues().then(result => {
      if (result.success) {
        setPagination(result.pagination);
        setuserData(result.records);
        setInProgress(false);
      } else {
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setuserData([]);
        setPagination(null);
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    getPaymentIssues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    getPaymentIssues();
  };

  const handleSubmit = (e, obj, type) => {
    e.preventDefault();
    const objData = { ...obj, type };
    setSelectableObj(objData);
    setVisible(!visible);
  };


  const handleSearch = (value) => {
    getPaymentIssues(value);
  }

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sortDirections: ['ascend','descend','ascend'],
      width: 150,
      sorter: (a, b) => {
        a = a.fullName || '';
        b = b.fullName || '';
        return a.localeCompare(b);
      },
      render: (text) => (
        <span>
          <u>{text} </u>
        </span>
      ),
    },
    {
      title: 'Payment Info',
      dataIndex: 'paymentInfo',
      sortDirections: ['ascend','descend','ascend'],
      key: 'paymentInfo',
      width: 150,
      sorter: (a, b) => {
        a = a.paymentInfo || '';
        b = b.paymentInfo || '';
        return a.localeCompare(b);
      },
    render: (text) => <span>**** **** **{text}</span>,
    },
    {
      title: 'Last Charge Attempted',
      dataIndex: 'lastChargeAttempted',
      sortDirections: ['ascend','descend','ascend'],
      key: 'lastChargeAttempted',
      width: 250,
      sorter: (a, b) => {
        a = a.lastChargeAttempted || '';
        b = b.lastChargeAttempted || '';
        return a.localeCompare(b);
      },
      render: (text) => <span>{text !== null ? moment(text).format('MM/DD/YYYY hh:mm A') : '-'}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paymentStatus',
      sortDirections: ['ascend','descend','ascend'],
      key: 'paymentStatus',
      width: 150,
      sorter: (a, b) => {
        a = a.paymentStatus || '';
        b = b.paymentStatus || '';
        return a.localeCompare(b);
      },
    render: (text) => <span>{text}</span>,
    },
  ];


  if (userDetails && userDetails.userType === 'UNYTAG') {
    columns.push({
      title: '',
      dataIndex: 'age',
      sortDirections: ['ascend','descend','ascend'],
      key: 'age',
      render: (text, data) => (
        <>
          <Button
            className={`btn-style enterprisebuttons btn-info  `}
            onClick={e => {
              handleSubmit(e, data, 'freezeUser');
            }}>
            FREEZE TAG
          </Button>
          <Button
            className='btn-style btn-success enterpriseOptionbuttons'
            onClick={e => {
              handleSubmit(e, data, 'sendNewTag');
            }}>
            SEND NEW TAG
          </Button>
        </>
      ),
      align: 'right',
    });
  }

  const handleModalCancel = modalType => {
    setSelectableObj('');
    setVisible(!visible);
  };

  const handleModalType = (modalType, sid) => {
    if (modalType === 'freezeUserAccount') {
      freezeAccount(sid).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          getPaymentIssues();
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
    if (modalType === 'deleteUserAccount') {
      deleteEnterpriceUserById(sid).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);

          alertMessage(
            `${name.toUpperCase()} ` + `HAS BEEN DELETED`,
            'error',
            3
          );
          history.push('/unytag/web/enterprise');
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
  };

  const renderModal = () => {
    let title;
    let description;
    let btnText;
    let btnType;
    let shippingAddress;
    if (selectableObj.type === 'deleteUser') {
      title = 'Do you want to delete the user?';
      description =
        'If you delete the admin user without assigning another user as admin, the entire account will be deleted.';
      btnText = 'DELETE ADMIN';
      btnType = 'btn-danger';
    } else if (selectableObj.type === 'deleteUserAccount') {
      title = 'Do you want to delete the account?';
      description =
        'If you delete the account all tags attached to this account will be deactivated forever. This is irreversible.';
      btnText = 'DELETE ACCOUNT';
      btnType = 'btn-danger';
    } else if (selectableObj.type === 'freezeUser') {
      title = 'Do you want to freeze this user’s tag?';
      description =
        'The user will no longer be able to use their tag for any tolls.';
      btnText = 'FREEZE TAG';
      btnType = 'btn-info';
    } else if (selectableObj.type === 'freezeUserAccount') {
      title = 'Do you want to freeze the account?';
      description =
        'If you freeze the account all tags attached to this account will be deactivated till the account is unfrozen.';
      btnText =
        enterPrise.status === 'ACTIVE' ? 'FREEZE ACCOUNT' : 'UNFREEZE ACCOUNT';
      btnType = 'btn-info';
    } else {
      title = 'Enter the shipping address to send the tag.';
      description =
        'The shipping address is always auto-populated with the shipping address on file but you can change it if necessary.';
      btnText = 'SHIP TAG';
      btnType = 'btn-style btn-success enterpriseOptionbuttons buttonWidth1';
      shippingAddress = '1800 Lawn Lane, Austin, TX 77001';
    }
    return (
      <Modal
        className='toll-authority'
        title={title}
        visible={visible}
        width='500px'
        footer={null}
        onCancel={() => {
          handleModalCancel(selectableObj.type);
        }}>
        {selectableObj.name && (
          <p className='text-center'>
            {selectableObj.type === 'deleteUser'
              ? `Admin: ${selectableObj.name}`
              : `User: ${selectableObj.name}`}
          </p>
        )}
        {shippingAddress && (
          <div>
            <p className='text-center'>Admin: Jones Drew</p>
            <div style={{ padding: '10px 0px' }}>
              <span className='sendTagUser'>SHIPPING ADDRESS</span>
              <Input value={shippingAddress}   autoComplete='new-password' style={{ height: '52px' }} />
            </div>
          </div>
        )}
        <div className='modalDescrition'>
          <p>Disclaimer:</p>
          <p>{description}</p>
        </div>
        <Row type='flex' justify='center'>
          <ReuseButton
            text={btnText}
            className={`btn-style ${btnType}`}
            onClick={e => {
              handleModalType(selectableObj.type, id);
            }}
          />
        </Row>
      </Modal>
    );
  };

  return (
    <div>
      <Spin spinning={inProgress}>
        <p className='EntTitleText'>
          <Row type='flex' justify='space-between'>
            <Col xs={8} className='d-flex justify-start'>
              <Row type='flex' justify='start' align='middle'>
                <span className='PageTitle'>Issues with Payments ({pagination!==null? pagination.totalCount:0})</span>
              </Row>
            </Col>
            <Col xs={10} className='' />
            <Col lg={6}>
              <ReuseInputField
                placeholder='Search by number, name...'
                width='800px'
                className='EntSearchBar'
                onchange={handleSearch}
              />
            </Col>
          </Row>
        </p>
        <div className='Basecontainer'>
          <Row gutter={30}>
            <Col span={24} className='tablePadding  mar-top-10 '>
              <p className='TitleText mar-bottom-20'>
                View all the accounts that have had issues with payment
                processing.
              </p>
              <Table
                className='BaseTable'
                dataSource={userData}
                columns={columns}
                showHeader
                pagination={
                  pagination !== null
                    ? {
                        total: parseInt(pagination.totalCount, 10),
                        current: parseInt(pagination.currentPage, 10),
                        pageSize: parseInt(pagination.perPage, 10),
                        pageSizeOptions: [5,10,20],
                      }
                    : {}
                }
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </div>

        {visible && renderModal()}
      </Spin>
    </div>
  );
};

export default PaymentIssues;
