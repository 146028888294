/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  message,
  Select,
} from '../../common/AntdUiComponents';
import AddressForm from '../../common/Addressform';

const roles = ['User', 'Admin'];
const Profile = () => {
  const params = useParams();
  const role = roles.filter((userRole) => userRole === params.type);
  const profileData = {
    fullName: {
      title: 'FULL NAME',
      value: '',
      valueFor: 'fullName',
      placeholder: 'Full name',
      type: 'text',
    },
    email: {
      title: 'WORK EMAIL ADDRESS (username)',
      value: '',
      valueFor: 'email',
      placeholder: 'Email',
      type: 'email',
    },
    companyName: {
      title: 'COMPANY NAME',
      value: '',
      valueFor: 'companyName',
      placeholder: ' Company Name',
      type: 'text',
    },
    companyAddress: {
      title: 'COMPANY ADDRESS',
      value: '',
      valueFor: 'companyAddress',
      placeholder: 'Address',
      type: 'address',
    },
    shippingAddress: {
      title: 'SHIPPING ADDRESS',
      value: '',
      valueFor: 'shippingAddress',
      placeholder: 'Address',
      type: 'address',
    },
    billingAddress: {
      title: 'BILLING ADDRESS',
      value: '',
      valueFor: 'billingAddress',
      placeholder: 'Address',
      type: 'address',
    },
    phoneNumber: {
      title: 'PHONE NUMBER',
      value: '',
      valueFor: 'phoneNumber',
      placeholder: 'Phone number',
      type: 'text',
    },
    role: {
      title: 'ROLE',
      value: '',
      valueFor: 'role',
      placeholder: 'Role',
      type: 'select',
    },
  };
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [profile, setProfile] = useState(profileData);

  useEffect(() => {
    const isEdit = () => {
      if (params.name !== undefined) {
        setIsEditProfile(true);
      } else {
        setIsEditProfile(false);
      }
    };
    isEdit();
  }, [params]);

  const handleProfile = (type) => {
    setIsEditProfile(!isEditProfile);
    if (type === 'save') {
      message.success({
        content: 'ALL CHANGES HAVE BEEN SAVED!',
        className: 'bg-green',
        duration: 5,
      });
    }
  };

  const handleOnAddressChange = () => {};

  const handleOnChange = (field, value) => {
    profileData[field].value = value;
    setProfile(profileData);
  };

  const profileObj = Object.values(profile);
  return (
    <div>
      <p className="PageTitle">
        <Row type="flex" justify="space-between">
          {params.name !== 'admin' && (
            <span className="profileTitle">{params.name}</span>
          )}
          {isEditProfile ? (
            <Button
              className="AddButton"
              onClick={() => {
                handleProfile('edit');
              }}
            >
              {params.name !== undefined ? 'EDIT PROFILE' : 'ADD PROFILE'}
            </Button>
          ) : (
            <Button
              className="btn-style btn-success SaveButton"
              onClick={() => {
                handleProfile('save');
              }}
            >
              SAVE
            </Button>
          )}
        </Row>
      </p>
      <div className="Basecontainer">
        <Col span={24} style={{ padding: '2%' }}>
          <div className="profile-container">
            <Form layout="vertical" className="formFieldsData addEnts">
              {profileObj.map((field) => (
                <Col lg={10}>
                  <Form.Item label={field.title}>
                    {field.type === 'select' ? (
                      <Select
                        placeholder="Select Role"
                        disabled={isEditProfile}
                        className="authlabels1"
                        style={{ width: '88%' }}
                      >
                        {role.map((Userrole) => (
                          <Select.Option key={Userrole} value={Userrole}>
                            {Userrole}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : field.type === 'address' ? (
                      <AddressForm
                        autoComplete
                        twoRows
                        showReqFields
                        disabled={isEditProfile}
                        address={field.value}
                        onChange={(element, value, obj) =>
                          handleOnAddressChange(
                            field.valueFor,
                            element,
                            value,
                            obj,
                          )
                        }
                      />
                    ) : (
                      <Input
                        className="input-field"
                        type={field.type}
                        value={field.value}
                        placeholder={field.placeholder}
                        autoComplete='new-password'
                        disabled={isEditProfile}
                        onChange={(e) =>
                          handleOnChange(field.valueFor, e.target.value)
                        }
                        style={{ marginBottom: 20 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Form>
          </div>
        </Col>
        <Col lg={2} />
      </div>
    </div>
  );
};

export default Profile;
