/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Spin,
  Upload,
} from '../../common/AntdUiComponents';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import {
  freezeAccount,
  fetchEnterpriceUserById,
  freezeUserTag,
  shipTag,
  deleteUserById,
  deleteEnterpriceUserById,
} from '../../api/enterprise/enterprise';
import { alertMessage,validateAddressForm } from '../../common/Common';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import AddressForm from '../../common/Addressform';
import EnterPriseUserForm from './EnterPriseUserForm';
import ExportCSV from '../../utils/reusableComponents/ExportCSV';

const roles = ['User', 'Admin'];
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const user = localStorage.getItem('user');
const userDetails = user!==''? JSON.parse(user) : { };

const UserAccounts = ({
  name,
  userData,
  tableChange,
  id,
  loadEntUsers,
  pagination,
  handleUserSearch,
  usersType,
  adminsCount
}) => {
  const history = useHistory();
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const [selectableObj, setSelectableObj] = useState('');
  const [enterPrise, setEnterprise] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [address, setaddress] = useState('');
  const [showViewModal, setShowViewModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [status, setTagStaus] = useState('');
  const [upload, setUpload] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    license: '',
  });

  const userDummyData = [
    {
      "Full Name": '',
      "Email": '',
      "Role": '',
      "Contact Number": '',
    },
  ];

  const getEnterpriseUserById = () => {
    setInProgress(true);
    fetchEnterpriceUserById(id).then(result => {
      if (result.success) {
        setEnterprise(result.data);
        const address = {
          address_line1: result.data.streetNumber,
          address_line2: result.data.streetName,
          state:result.data.state,
          city:result.data.city,
          zipcode: result.data.zipCode,
          country: 'US',
        };
        setaddress(address)
        setInProgress(false);
      } else {

        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    // if (params.id !== undefined) {
    getEnterpriseUserById();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnAddressChange = (addressType, element, value, obj) => {
    setaddress(obj)
    console.log(obj)

  };

  const onFocus = () => {
    if(address!==''){
      address.streetNumber =  address.address_line1!==undefined?address.address_line1:'';
      address.streetName =  address.address_line2!==undefined?address.address_line2:'';
      }
    const addrressError = validateAddressForm(address);
    if(addrressError){
      const errors = [];
      errors.push(`${addrressError}`);
      setErrors(errors)
      showError(true)
    }else{
      showError(false)
    }
    handleOnAddressChange('','','',address);
    console.log('sdsdsd')
  }

  const sendtoStatus = () => {
    history.push('/unytag/web/orderedtags');
  }

  const addNewUser = () => {
    setState({
      name: '',
      email: '',
      phone: '',
      role: '',
      license: '',
    });
    setShowViewModal(true);
  };

  const handleSubmit = (e, obj, type, status) => {
    e.preventDefault();
    if(type==='sendNewTag'){
      getEnterpriseUserById()
      showError(false)
      setErrors([])
    }
    const objData = { ...obj, type };
    setSelectableObj(objData);
    setDisableBtn(false);
    setTagStaus(status);
    setVisible(!visible);
  };

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sortDirections: ['ascend','descend','ascend'],
      width: 150,
      sorter: (a, b) => {
        a = a.firstName || '';
        b = b.firstName || '';
        return a.localeCompare(b);
      },
      render: (text, data) => {
        const { firstName } = data;
        const lastName =
          data.lastName !== 'null' && data.lastName !== null
            ? data.lastName
            : '';
        return (
          <span>
            {text !== '' ? (
              <span>{`${firstName} ${lastName}`}</span>
            ) : (
              <u
                onClick={addNewUser}
                onKeyPress={addNewUser}
                role='link'
                tabIndex='0'>
                Add new user
              </u>
            )}
          </span>
        );
      },
    },
    {
      title: 'Tag ID',
      dataIndex: 'tagIdentificationNumber',
      key: 'tagIdentificationNumber',
      width: 150,
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => {
        a = a.tagIdentificationNumber || '';
        b = b.tagIdentificationNumber || '';
        return a.localeCompare(b);
      },
      render: text => <span>{text !== null ? text : '-'}</span>,
    },
    {
      title: 'Account Type',
      dataIndex: 'role',
      key: 'role',
      sortDirections: ['ascend','descend','ascend'],
      width: 150,
      sorter: (a, b) => {
        a = a.role || '';
        b = b.role || '';
        return a.localeCompare(b);
      },
    },
    {
      title: '',
      dataIndex: 'age',
      key: 'age',
      render: (text, data) => (
        <div className='swal-footer'>
          <Button
            className={`btn-style btn-danger enterpriseOptionbuttons entButtonsForUser ${(adminsCount===1 && data.role==='Admin')?'btn-disabled':''}`}
            disabled=
            {(adminsCount===1 && data.role==='Admin')||(userDetails.id === data.id &&  data.role==='Admin')}
            onClick={e => {
              handleSubmit(e, data, 'deleteUser');
            }}>
            DELETE USER
          </Button>
          <Button
            className={`btn-style enterpriseOptionbuttons entButtonsForUser ${
              data.tagIdentificationNumber === null
                ? 'btn-disabled'
                : enterPrise.status !== 'INACTIVE' &&
                  data.tagStatus !== 'INACTIVE'
                ? 'btn-info'
                : 'btn-freeze'
            }  `}
            disabled={
              enterPrise.status === 'INACTIVE' ||
              data.tagIdentificationNumber === null
            }
            onClick={e => {
              handleSubmit(e, data, 'freezeUser', data.tagStatus);
            }}>
            {(enterPrise.status !== 'INACTIVE' &&
              data.tagStatus !== 'INACTIVE') ||
            data.tagIdentificationNumber === null
              ? 'FREEZE TAG'
              : 'TAG FROZEN'}
          </Button>
          {/* )} */}

            {data.tagOrderStatus !== null ?
           <span className='tagsendeer'> <Button><u className='tagSentOrder' onClick={sendtoStatus}>Tag Sent: View order</u></Button>
            </span>

:
<Button
className={`btn-style  enterpriseOptionbuttons entButtonsForUser ${enterPrise.status === 'INACTIVE'? 'btn-disable':'btn-success'}` }
disabled={enterPrise.status === 'INACTIVE'}
onClick={e => {
  handleSubmit(e, data, 'sendNewTag');
}}>
SEND NEW TAG
</Button>
            }

        </div>
      ),
      align: 'right',
    },
  ];

  const handleModalCancel = modalType => {
    setSelectableObj('');
    setVisible(!visible);
  };

  const handleModalType = (modalType, accId) => {
    if (modalType === 'freezeUserAccount') {
      setDisableBtn(true);
      freezeAccount(accId).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          getEnterpriseUserById();
          console.log(result)
          if (result.data.status === 'ACTIVE') {
            alertMessage('THE ACCOUNT HAS BEEN UNFREEZED', 'warning', 3);
          } else {
            if(userDetails.userType === 'ENTERPRISE'){
              localStorage.clear();
              window.location.replace("/unytag/web")
            }
            alertMessage('THE ACCOUNT HAS BEEN FROZEN', 'warning', 3);
          }
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
    if (modalType === 'deleteUserAccount') {
      setDisableBtn(true);
      deleteEnterpriceUserById(accId).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);

          alertMessage(
            `${name.toUpperCase()} ` + `HAS BEEN DELETED!`,
            'error',
            3
          );
          history.push('/unytag/web/enterprise');
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
    if (modalType === 'freezeUser') {
      setDisableBtn(true);
      const entName =
        params.name !== undefined ? params.name : userDetails.company;
      freezeUserTag(entName, selectableObj.id).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          loadEntUsers();
          getEnterpriseUserById();
          if (result.data.tags.status=== 'ACTIVE') {
            alertMessage('THE TAG HAS BEEN UNFREEZED!', 'warning', 3);
          } else {
            alertMessage('THE TAG HAS BEEN FROZEN!', 'warning', 3);
          }

        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
    if (modalType === 'deleteUser') {
      setDisableBtn(true);
      deleteUserById(selectableObj.company, selectableObj.id).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          loadEntUsers();
          alertMessage(
            `${selectableObj.firstName.toUpperCase()} ` + `HAS BEEN DELETED!`,
            'error',
            3
          );
        } else {
          alertMessage(result.errors.message.message, 'error', 3);
        }
      });
    }
    if (modalType === 'sendNewTag') {
      setDisableBtn(true);
      setInProgress(true);
      shipTag(selectableObj.company, selectableObj.id).then(result => {
        if (result.success) {
          setVisible(!visible);
          loadEntUsers();
          setSelectableObj('');

          setInProgress(false);
          const lastName =
            selectableObj.lastName !== null
              ? selectableObj.lastName.toUpperCase()
              : '';
          alertMessage(
            `${
              `A NEW TAG HAS BEEN SENT TO` + ' '
            }${selectableObj.firstName.toUpperCase()}${lastName?(' ' + lastName):''}!`,
            'success',
            3
          );
        } else {
          setInProgress(false);
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
  };
  const handleUploadClose = () => {
    setUpload(false);
  };

  const uploadUsers = () => {
    setUpload(true);
  };

  const renderUpload = () => {
    return (
      <BaseModal width='30%' onCancel={() => handleUploadClose()}>
        <div style={{ textAlignLast: 'center' }}>
          <Upload
            accept='.xlsx'
            showUploadList={true}
            beforeUpload={file => {
              const reader = new FileReader();

              reader.onload = e => {};
              reader.readAsText(file);
              return false;
            }}>
            <Button>Click to Upload</Button>
          </Upload>
        </div>
      </BaseModal>
    );
  };

  const renderViewModel = () => {
    return (
      <BaseModal width='70%' onCancel={() => handleOnModalClose()}>
        <EnterPriseUserForm
          newData
          userData={userData}
          hideModal={handleOnModalClose}
          loadUsers={loadEntUsers}
        />
      </BaseModal>
    );
  };

  const renderModal = () => {
    let title;
    let description;
    let btnText;
    let btnType;
    // eslint-disable-next-line no-shadow
    let name;
    let shippingAddress;
    let disabled;
    if (selectableObj.type === 'deleteUser') {
      title =
        selectableObj.role === 'Admin'
          ? 'Do you want to delete the admin?'
          : 'Do you want to delete the user?';
      description = (
        <span>
          If you delete the{' '}
          {selectableObj.role === 'Admin' ? (
            <>
              {' '}
              <span className='boldFont'>admin user</span>{' '}
              <span>
                without assigning another user as admin, the entire account will
                be deleted.
              </span>{' '}
            </>
          ) : (
            <>
              {' '}
              <span className='boldFont'> user</span>{' '}
              <span>their tag will be disabled</span>{' '}
            </>
          )}{' '}
        </span>
      );
      name = (
        <span>
          {selectableObj.role === 'Admin' ? 'Admin:' : 'User:'} &nbsp;
          {selectableObj.firstName} {selectableObj.lastName}
        </span>
      );
      btnText =
        selectableObj.role === 'Admin' ? 'DELETE  ADMIN' : 'DELETE USER';
      btnType = `${disableBtn ? 'btn-disabled' : 'btn-danger'}`;
    } else if (selectableObj.type === 'deleteUserAccount') {
      title = 'Do you want to delete the account?';
      description =
        'If you delete the account all tags attached to this account will be deactivated forever. This is irreversible.';
      btnText = 'DELETE ACCOUNT';
      btnType = 'btn-danger';
    } else if (selectableObj.type === 'freezeUser') {
      title =
        status === 'ACTIVE'
          ? 'Do you want to freeze this user’s tag?'
          : 'Do you want to unfreeze this user’s tag?';
          name = (
            <span>
              {selectableObj.role === 'Admin' ? 'Admin:' : 'User:'} &nbsp;
              {selectableObj.firstName} {selectableObj.lastName}
            </span>
          );
      description =
        status === 'ACTIVE'
          ? 'The user will no longer be able to use their tag for any tolls.'
          : 'You are about to unfreeze the user tag';
      btnText = status === 'ACTIVE' ? 'FREEZE TAG' : 'UNFREEZE TAG';
      btnType = `${disableBtn ? 'btn-disabled' : 'btn-info '}`;
    } else if (selectableObj.type === 'freezeUserAccount') {
      title =
        enterPrise.status === 'ACTIVE'
          ? 'Do you want to freeze the account?'
          : 'Do you want to unfreeze the account?';
      description =
        enterPrise.status === 'ACTIVE'
          ? 'If you freeze the account all tags attached to this account will be deactivated till the account is unfrozen.'
          : 'You are about to unfreeze this account';
      btnText =
        enterPrise.status === 'ACTIVE' ? 'FREEZE ACCOUNT' : 'UNFREEZE ACCOUNT';
      btnType = 'btn-info';
    } else {
      name = (
        <span>
          User: &nbsp; {selectableObj.firstName} {selectableObj.lastName}
        </span>
      );
      title = 'Enter the shipping address to send the tag.';
      description =
        'The shipping address is always auto-populated with the shipping address on file but you can change it if necessary.';
      btnText = 'SHIP TAG';
      btnType = `btn-style enterpriseOptionbuttons buttonWidth1 ${
        disableBtn ? 'btn-disabled' : 'btn-success'
      }`;
      shippingAddress = '1800 Lawn Lane, Austin, TX 77001';
    }
    return (
      <Modal
        className='toll-authority'
        title={title}
        visible={visible}
        width='500px'
        footer={null}
        onCancel={() => {
          handleModalCancel(selectableObj.type);
        }}>
        {selectableObj.name && (
          <p className='text-center'>
            {selectableObj.type === 'deleteUser'
              ? `Admin: ${selectableObj.name}`
              : `User: ${selectableObj.name}`}
          </p>
        )}
        {shippingAddress && (
          <div>
            <p className='text-center'>{name}</p>
            <div   className={` sendTagsPadding ${error ? 'errorAddress' : ''}`} >
              <span className='sendTagUser'>SHIPPING ADDRESS</span>

              <AddressForm
                autoComplete
                twoRows
                showReqFields
                onFocus={onFocus}
                address={address}
                onChange={(element, value, obj) =>
                  handleOnAddressChange('address', element, value, obj)
                }
              />
               {error && <span className='errorMessageAddress'>{errors} in shipping address</span>}
            </div>
          </div>
        )}
        <div className='modalDescrition'>
          {(selectableObj.type === 'deleteUser'||selectableObj.type === 'freezeUser') && (
            <p className='swal-footer userPaddings'>{name}</p>
          )}

          <p>Disclaimer:</p>
          <p>{description}</p>
        </div>
        <Row type='flex' justify='center'>
          <ReuseButton
            text={btnText}
            className={`btn-style ${btnType}`}
            onClick={e => {
              handleModalType(selectableObj.type, id);
            }}
            disabled={disableBtn || error }
          />
        </Row>
      </Modal>
    );
  };

  return (
    <div>
      <Spin spinning={inProgress} >
        <div className='EntTitleText userProfile'>
          <Row type='flex' justify='space-between'>
            <Col xs={8} className='d-flex justify-start'>
              <Row type='flex' justify='start' align='middle'>
                <p className='PageTitle'>
                  {name && name.charAt(0).toUpperCase() + name.slice(1)}
                </p>
              </Row>
            </Col>
            <Col xs={16} className=''>
              <Button
                className={`btn-style enterprisebuttons  ${
                  enterPrise.status === 'ACTIVE'
                    ? 'btn-info'
                    : 'btn-freeze buttonWidth1'
                }  `}
                onClick={e => {
                  handleSubmit(e, '', 'freezeUserAccount');
                }}>
                {enterPrise.status === 'ACTIVE' ? 'FREEZE ACCOUNT' : 'FROZEN'}
              </Button>
              <Button
                className='btn-style btn-danger enterprisebuttons'
                onClick={e => {
                  handleSubmit(e, '', 'deleteUserAccount');
                }}>
                DELETE ACCOUNT
              </Button>

              <ReuseInputField
                placeholder='Search by number, name...'
                width='800px'
                className='EntSearchBar'
                onChange={handleUserSearch}
              />
            </Col>
          </Row>
        </div>
        <div className='Basecontainer'>
          <Row gutter={30}>
            <Col span={24} className='tablePadding mar-top-10'>
              <Row className='tablePadding'>
                <Col lg={8} className='userTmp'>
                  <ExportCSV
                    className='leftFloat btn-style btn-info enterpriseOptionbuttons templateText templateBtn'
                    csvData={userDummyData}
                    fileName='User Template'
                    name='DOWNLOAD USER TEMPLATE'></ExportCSV>
                    <Button
                    className='btn-style btn-success enterpriseOptionbuttons uploadAdjust'
                    onClick={uploadUsers}>
                    UPLOAD USERS
                  </Button>
                </Col>
                <Col lg={8} className='text-center entUserText'>
                  Here are all the users associated with this account.
                </Col>
                <Col lg={8}>
                  <Button
                    className='rightFloat btn-style btn-info enterpriseOptionbuttons addEntnewUser'
                    onClick={addNewUser}>
                    ADD NEW USER
                  </Button>
                </Col>
              </Row>
              <Table
                className='BaseTable'
                dataSource={userData}
                columns={columns}
                showHeader
                pagination={pagination}
                onChange={tableChange}
              />
            </Col>
          </Row>
        </div>

        {visible && renderModal()}
        {upload && renderUpload()}
        {showViewModal && renderViewModel()}
      </Spin>
    </div>
  );
};

export default UserAccounts;
