import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import swal from 'sweetalert';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { getAdminUserDetails, updateAdmin } from '../../api/profile';

const user = localStorage.getItem('user');
let userDetails = user!==''? JSON.parse(user) : { };


const Profile = ({ sendName }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [userData, setuserData] = useState({});

  const [form] = Form.useForm();

  const getAdminDetails = () => {

    getAdminUserDetails(userDetails.id).then(result => {
      if (result.success) {
        // eslint-disable-next-line no-shadow
        const { email } = result.data;
        // eslint-disable-next-line no-shadow
        const name = `${result.data.firstName} ${result.data.lastName!==null ?result.data.lastName :''}`.trim();
        // eslint-disable-next-line no-shadow
        const userData = result.data;
        setuserData(userData);
        setEmail(email);
        setName(name);
        sendName(name);
        form.setFieldsValue({
          email,
          name,
        });
      } else {
        swal({
          title: 'Error',
          text: '',
          icon: 'error',
          button: 'OK',
        }).then(() => {
          getAdminDetails();
        });
      }
    });
  };

  useEffect(() => {
    getAdminDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {

    const formData = {};
    formData.email = email;
    // eslint-disable-next-line prefer-destructuring
    const nameObj = name.trim().split(" ");
    if(nameObj.length===1){
      formData.firstName = nameObj[0];
      formData.lastName=''
    }else{
      formData.firstName = nameObj[0];
      let lastnameObjArray = nameObj.slice(1);
      let lastnameObj = lastnameObjArray.join(' ');
      formData.lastName = lastnameObj
    }
    formData.id = userData.id;
    formData.phone = userData.phone;
    formData.role = userData.role;
    updateAdmin(userData.id, formData).then(result => {
      if (result.success) {
        setDisableBtn(true);
        userDetails.firstName= formData.firstName;
        userDetails.lastName= formData.lastName;
        localStorage.setItem('user', JSON.stringify(userDetails));


        swal({
          title: 'Success!',
          text: 'ADMIN DETAILS UPDATED SUCCESSFULLY',
          icon: 'success',
          button: 'OK',
        }).then(() => {
          setDisableBtn(false);
          getAdminDetails();
        });
      } else {
        setDisableBtn(false);
        swal('Oops!', result.errors.message.message[0], 'error');
      }
    });
  };

  const handleChange = key => value => {
    if (key === 'name') {
      setName(value);
    }
    if (key === 'email') {
      setEmail(value);
    }
  };

  return (
    <div className='LoginLayout'>
      <div className='LoginContentSize'>
        <Row className='forgetContent'>
          <Col lg={14} style={{ margin: '55px auto', textAlign: 'center' }}>
            <Col lg={15} offset={8}>
              <Form form={form} onSubmit={handleSubmit} layout='vertical'>
                <Form.Item
                  name='name'
                  rules={[
                    { required: true, message: 'Please enter admin name!' },
                  ]}
                  label='ADMIN NAME'
                  className='Profilelabels'>
                  <ReuseInputField
                    type='text'
                    placeholder='John Doe'
                    height='52px'
                    value={name}
                    onChange={handleChange('name')}
                  />
                </Form.Item>
                <Form.Item
                  name='email'
                  validateTrigger='onBlur'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email!',
                      validationTrigger: 'onBlur',
                    },
                    {
                      type: 'email',
                      message: 'Please provide a valid email address!',
                      validationTrigger: 'onBlur',
                    },
                  ]}
                  label='WORK EMAIL ADDRESS'
                  className='Profilelabels'>
                  <ReuseInputField
                    type='text'
                    placeholder='john@mainstreet.com'
                    disabled
                    height='52px'
                    value={email}
                    onChange={handleChange('email')}
                  />
                </Form.Item>
                <ReuseButton
                  text='UPDATE PROFILE'
                  disabled={email === '' || name === '' || disableBtn}
                  className='center1auth'
                  onClick={handleSubmit}
                />
              </Form>
            </Col>
            <Col lg={6} />
          </Col>
          <Col lg={5} />
        </Row>
      </div>
    </div>
  );
};

export default Profile;
