/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import TollAuthorityCategory from './CategoryList';
import TollAuthorityFacility from './TollFacilities';
import TollPlazas from './TollPlazas';
import Payment from './Payment';
import Profile from './Profile';
import { Spin } from '../../common/AntdUiComponents';

const TollAuthorityInfo = () => {
  const params = useParams();

  const [title, setTitle] = useState(params.name);
  const [listVisible, setListVisible] = useState('category');
  const [searchField, setSearchField] = useState('');

  const handleTitle = (langValue, nextState) => {
    setTitle(langValue);
    setListVisible(nextState);
  };

  const handleSearch = (value) => {
    if (listVisible === 'category') {
      setSearchField(value);
    }
  };

  return (
    <div>
      {title !== 'Profile' && (
        <p className="ContainerTitleHeader">
          <span className="PageTitle">
            {title !== 'Profile' && <span>{title}</span>}{' '}
            {(listVisible === 'plazas' ||listVisible === 'facilities' ) && <span>(4)</span>}
          </span>
          <ReuseInputField
            placeholder={
              listVisible === 'category'
                ? 'Search by title...'
                : listVisible === 'facilities' || listVisible === 'plazas'
                ? 'Search by name...'
                : ''
            }
            className="SearchBar"
            onChange={handleSearch}
          />
        </p>
      )}
      {listVisible === 'category' ? (
        <TollAuthorityCategory
          onSelectTitle={handleTitle}
          dataValue={searchField}
          recordName={title}
        />
      ) : listVisible === 'facilities' ? (
        title === 'Profile' ? (
          <Profile />
        ) : (
          <TollAuthorityFacility onSelectTitle={handleTitle} />
        )
      ) : (
        <TollPlazas onSelectTitle={handleTitle} />
      )}
    </div>
  );
};

export default TollAuthorityInfo;
