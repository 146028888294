import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import swal from 'sweetalert';
import PhoneInput from 'react-phone-input-2';
import {
  Row,
  Col,
  Button,
  Form,
  message,
  Select,
  Spin
} from '../common/AntdUiComponents';
import logo1 from '../images/Unytaglogo.png'
import AddressForm from '../common/Addressform';
import { registerNewUser, checkRegisteredMailStatus } from '../api/user';
import { registerNewEntUser } from '../api/enterprise/enterprise';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const roles = ['User', 'Admin'];
const vehicles = ['Dry Van', 'Car', 'Reefer'];
const Invite = () => {
  const location = useLocation();
  const params = useParams();
  const parsed = qs.parse(location.search);
  const history = useHistory();

  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    vehicleSelected: '',
    address: '',
  });
  const [form] = Form.useForm();

  const setEmail = () => {
    form.setFieldsValue({
      email: parsed.email,
    });
    setState({
      ...state,
      email: parsed.email,
    });
  };

  const isEdit = () => {
    if (params.name !== undefined) {
      setIsEditProfile(true);
    } else {
      setIsEditProfile(false);
    }
  };

  useEffect(() => {
    isEdit();
    setEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    checkLinkStatus();
  }, []);

  const checkLinkStatus = () => {
    localStorage.clear();
    checkRegisteredMailStatus(parsed.email).then(result => {
      if (result.success) {
        setVisible(true);
      } else {
        setVisible(false);
        swal(
          'Oops!',
          result.errors.message.message,
          'error'
        ).then(() => {
              history.push('/unytag/web');
        })
      }
    });
  };

  const handleRoleChange = (e, value) => {
    setState({
      ...state,
      [e]: value,
    });
  };

  const handleProfile = type => {
    setIsEditProfile(!isEditProfile);
    if (type === 'save') {
      message.success({
        content: 'ALL CHANGES HAVE BEEN SAVED!',
        className: 'bg-green',
        duration: 5,
      });
    }
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    setState({
      ...state,
      [addressType]: obj,
    });
  };

  const submitForm = e => {
    setIsLoading(true)
    e.preventDefault();
    if (state.name.trim().length !== 0) {
      if (state.phone.length >= 11) {
        const formData = {};
        // eslint-disable-next-line prefer-destructuring
        const nameObj = state.name.trim().split(' ');
        if (nameObj.length === 1) {
          formData.firstName = nameObj[0];
          formData.lastName = '';
        } else {
          formData.firstName = nameObj[0];
          let lastnameObjArray = nameObj.slice(1);
          let lastnameObj = lastnameObjArray.join(' ');
          formData.lastName = lastnameObj;
        }
        formData.email = state.email;
        formData.identificationNumber = 'string';
        formData.phone = state.phone.includes('+') ?state.phone:'+' + state.phone;
        formData.role = state.role;
        formData.mobilePin = '';
        formData.password = 'string';
        if (parsed.usertype === 'ENTERPRISE') {
          formData.userTypeId = 3;
          registerNewEntUser(
            formData,
            parsed.company,
            parsed.usertype,
            parsed.token
          ).then(result => {
            if (result.success) {
              setIsLoading(false)
              swal({
                title: 'Success!',
                text: `REGISTERED SUCCESSFULLY!`,
                icon: 'success',
                button: 'OK',
              });
              // localStorage.setItem('token', '');
              // localStorage.setItem('user', '');
              localStorage.clear();
              history.push('/unytag/web');
            } else {
              setIsLoading(false)
              swal(
                'Oops!',
                result.errors.message.message,
                'error'
              );
            }
          });
        } else {
          if (state.phone.length >= 11) {
            formData.userTypeId = 1;
            registerNewUser(formData, parsed.token).then(result => {
              if (result.success) {
                setIsLoading(false)
                swal({
                  title: 'Success!',
                  text: `REGISTERED SUCCESSFULLY!`,
                  icon: 'success',
                  button: 'OK',
                });
                // localStorage.setItem('token', '');
                // localStorage.setItem('user', '');
                localStorage.clear();
                history.push('/unytag/web');
              } else {
                setIsLoading(false)
                swal(
                  'Oops!',
                  result.errors.message.message,
                  'error'
                );
              }
            });
          } else {
            setIsLoading(false)
            swal('PLEASE ENTER A CORRECT PHONE NUMBER', 'error');
          }
        }
      } else {
        setIsLoading(false)
        swal('Oops!', 'Please enter a valid phone number', 'error');
      }
    } else {
      setIsLoading(false)
      swal('Oops!', 'Please enter a valid name', 'error');
    }
  };

  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };

  return (
    <div className='register'>

      {!visible ? (
        <div>
          {' '}

          <div className='EntBasecontainer'>
            <Col span={24} style={{ padding: '2%' }}>
              <div className='profile_height'>

              </div>
            </Col>
          </div>
        </div>
      ) : (
        <div>
           <Spin spinning={isLoading}>
          <p className='PageTitle'>
            <Row type='flex' justify='space-between'>
              {params.name !== 'admin' && (
                <span className='profileTitle'>{params.name}</span>
              )}
              {isEditProfile ? (
                <Button
                  className='AddButton'
                  onClick={() => {
                    handleProfile('edit');
                  }}>
                  {params.name !== undefined ? 'EDIT PROFILE' : 'ADD PROFILE'}
                </Button>
              ) : (
                <Button
                  className='btn-style btn-success SaveButton'
                  onClick={submitForm}
                  disabled={
                    state.name.trim() === '' ||
                    state.email === '' ||
                    state.phone === '' ||
                    state.phone.length < 11 ||
                    !re.test(String(state.email).toLowerCase()) ||
                    (parsed.usertype === 'ENTERPRISE' && state.role === '')
                  }>
                  SAVE
                </Button>
              )}
            </Row>
          </p>
          <div className='EntBasecontainer'>
            <Col span={24} style={{ padding: '2%' }}>
              <div className='profile_height'>
                <Form form={form} layout='vertical' className='addUsers'>
                  <div className='addtolls fullWidth'>
                    <Row className='mar-bottom-20'>
                      <Col lg={10}>
                        <Form.Item
                          name='name'
                          validateTrigger='onBlur'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name!',
                              validationTrigger: 'onBlur',
                            },
                            () => ({
                              validator(rule, value) {
                                if (value === undefined) {
                                  return Promise.resolve();
                                }
                                if (value.length === 0 && value.trim() === '') {
                                  return Promise.resolve();
                                }

                                if (value.length > 0 && value.trim() !== '') {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error('Please enter a valid name.')
                                );
                              },
                            }),
                          ]}
                          label='FULL NAME'
                          className='authlabels'>
                          <ReuseInputField
                            className='TagInput'
                            width='100%'
                            placeholder='Full Name'
                            disabled={isEditProfile}
                            value={state.name}
                            onChange={handleChange('name')}
                          />
                        </Form.Item>
                      </Col>
                      <Col offset={4} lg={10}>
                        <Form.Item
                          name='email'
                          validateTrigger='onBlur'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter email!',
                              validationTrigger: 'onBlur',
                            },
                            {
                              type: 'email',
                              message: 'Please provide a valid email address!',
                              validationTrigger: 'onBlur',
                            },
                          ]}
                          label='EMAIL ADDRESS (username)'
                          className='authlabels'>
                          <ReuseInputField
                            className='TagInput'
                            width='100%'
                            disabled
                            type='email'
                            placeholder='xyz@ymail.com'
                            value={state.email}
                            onChange={handleChange('email')}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className='mar-bottom-40'>
                      {parsed.usertype === 'UNYTAG' && (
                        <Col span={10}>
                          <span className='TagModalTitle'>
                            VEHICLE SELECTED{' '}
                          </span>
                          <br />
                          <Select
                            placeholder='Select one'
                            value={state.vehicleSelected}
                            disabled={isEditProfile}
                            style={{ width: '100%' }}
                            onChange={e =>
                              handleRoleChange('vehicleSelected', e)
                            }>
                            {vehicles.map(vehicle => (
                              <Select.Option key={vehicle} value={vehicle}>
                                {vehicle}
                              </Select.Option>
                            ))}
                          </Select>
                        </Col>
                      )}

                      <Col
                        offset={parsed.usertype === 'UNYTAG' ? 4 : ''}
                        span={10}
                        className='entUserAdd'>
                        <Form.Item
                          name='phone'
                          validateTrigger='onBlur'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a phone number!',
                              validationTrigger: 'onBlur',
                            },
                            () => ({
                              validator(rule, value) {
                                console.log(value);
                                if (
                                  value === undefined ||
                                  value.length === 11 ||
                                  value.length === 12
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error(
                                    'Please enter a valid phone number.'
                                  )
                                );
                              },
                            }),
                          ]}
                          label='PHONE NUMBER'
                          className='authlabels'>
                          <PhoneInput
                            country='us'
                            onlyCountries={['us', 'in']}
                            specialLabel=''
                            value={state.phone}
                            onChange={handleChange('phone')}
                            disabled={isEditProfile}
                          />
                        </Form.Item>
                      </Col>
                      {parsed.usertype === 'ENTERPRISE' && (
                        <Col offset={4} span={10} className='entUserAdd'>
                          <Form.Item
                            name='role'
                            validateTrigger='onBlur'
                            rules={[
                              {
                                required: true,
                                message: 'Please select a role!',
                                validationTrigger: 'onBlur',
                              },
                            ]}
                            label='ROLE'
                            className='authlabels'>
                            <Select
                              placeholder='Select one'
                              value={state.role}
                              disabled={isEditProfile}
                              style={{ width: '100%' }}
                              onChange={e => handleRoleChange('role', e)}>
                              {roles.map(role => (
                                <Select.Option key={role} value={role}>
                                  {role}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row className='mar-bottom-40'>
                      {parsed.usertype === 'UNYTAG' && (
                        <Col span={10}>
                          <span className='TagModalTitle'>
                            SHIPPING ADDRESS{' '}
                          </span>
                          <br />
                          <AddressForm
                            autoComplete
                            twoRows
                            showReqFields
                            disabled={isEditProfile}
                            address={state.address}
                            onChange={(element, value, obj) =>
                              handleOnAddressChange(
                                'address',
                                element,
                                value,
                                obj
                              )
                            }
                            style={{ marginBottom: 20 }}
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={2} />
          </div>
          </Spin>
        </div>
      )}
    </div>
  );
};

export default Invite;
