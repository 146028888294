import React from 'react';
import { Badge, Avatar, Menu, Dropdown } from '../common/AntdUiComponents';
import { BellOutlined, DownOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

const HeaderLinks = () => {
  let history = useHistory();
  let location = useLocation();

  const user = localStorage.getItem('user');
  const userDetails = user!==''? JSON.parse(user) : { };

  const menu = (
    <Menu onClick={event => handleMenuClick(event)}>
      <Menu.Item key='1'>Logout</Menu.Item>
      <Menu.Item key='2' onClick={event => handleViewProfile(event)}>
        View Profile
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = e => {
    if (e.key === '1') {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      history.push('/unytag/web/');
      window.location.reload(false);
    }
  };

  const handleViewProfile = e => {
    if (e.key === '2') {
      history.push('/unytag/web/profile');
      window.location.reload(false);
    }
  };

  return (
    <div
      className={
        location.pathname === '/unytag/web/profile'
          ? 'HeaderDispaly1'
          : 'HeaderDispaly'
      }>
      <Badge count={1} className='NotificationBadge' offset={[-10, 10]}>
        <Avatar
          className={
            location.pathname === '/unytag/web/profile'
              ? 'NotificationIconProfile'
              : 'NotificationIcon'
          }
          size='large'
          icon={<BellOutlined />}
        />
      </Badge>
      <span className='AvatarIcon'>
        <Avatar shape='square' className= {
            location.pathname === '/unytag/web/profile'
              ? 'avtarBackground'
              : 'avtarBackgroundBlue'
          }>
{userDetails && userDetails.firstName.substring(0, 1).toUpperCase()} {userDetails && (userDetails.lastName!==null?  userDetails.lastName.substring(0, 1).toUpperCase(): userDetails.firstName.substring(0, 1).toUpperCase())}


{/* {userDetails.firstName.substring(0, 1).toUpperCase()} {userDetails.lastName!==null ?userDetails.lastName.substring(0, 1).toUpperCase():userDetails.firstName.substring(0, 1).toUpperCase()} */}
        </Avatar>
      </span>
      <span></span>
      <Dropdown overlay={menu}>
        <Avatar
          className={
            location.pathname === '/unytag/web/profile'
              ? 'DropdownBtnListProfile'
              : 'DropdownBtnList'
          }
          size='large'
          icon={<DownOutlined />}
        />
      </Dropdown>
    </div>
  );
};

export default HeaderLinks;
