import axios from 'axios/index';
import _ from 'lodash';
import AppConfig from '../config/AppConfig';

export function PageParams(page, perPage) {
  return `pageNo=${page || 0}&pageSize=${perPage || AppConfig.perPage}`;
}

export function ApiUrl(path) {
  return `${AppConfig.apiUrl}${path}`;
}

export function HttpErrorMessage(error) {
  const status = error.response ? error.response.status : '';
  if (_.get(error, 'response.data.errors', null)) {
    return {
      message: error.response.data.errors,
      code: status,
    };
  }
  if (_.get(error, 'response.data', null)) {
    return {
      message: error.response.data,
      code: status,
    };
  }
  return [error.message, status];
}

export function ParseGeneralResponse(success, response, options) {
  const result = { success };
  if (!_.isEmpty(options.return)) {
    result.return = options.return;
  }
  if (success) {
    const dataKeys = options.responseDataKeys || {};
    _.forEach(dataKeys, (value, key) => {
      const valueKey = _.isEmpty(value) ? 'data' : `data.${value}`;
      result[key] = _.get(response, valueKey, null);
    });
  } else {
    result.errors = HttpErrorMessage(response);
  }
  return result;
}

export function Call(
  method,
  url,
  data,
  parseCallback,
  options = {},
  authorization = true,
) {
  const dataObj = {
    method,
    url,
    data,
  };
  if (authorization) {
    try {
      const token = localStorage.getItem('token');
      dataObj.headers = {
        Authorization: `Bearer ${token}`,
      };
    } catch {
      // localStorage.setItem('token', '');
      // localStorage.setItem('user', '');
      localStorage.clear();
    }
  }

  return new Promise((resolve) => {
    axios(dataObj)
      .then((response) => {
        resolve(parseCallback(true, response, options));
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          const refDataObj = {
            method: 'GET',
            url: 'http://18.224.19.222/unytag/refreshtoken',
            data: {},
          };
          const token = localStorage.getItem('token');
          refDataObj.headers = {
            Authorization: `Bearer ${token}`,
            isRefreshToken: true,
          };
          axios(refDataObj)
            .then((response) => {
              localStorage.setItem('token', response.data);
              if (response.status === 200) {
                window.location.reload();
              }
            })
            .catch(async (err) => {
              // localStorage.setItem('token', '');
              // localStorage.setItem('user', '');
              localStorage.clear();
              resolve(parseCallback(false, error, options));
            });
        }
        resolve(parseCallback(false, error, options));
      });
  });
}
