/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Divider,
  Button,
  Spin,
  Input,
} from '../../../common/AntdUiComponents';
import BaseModal from '../BaseModal';
import ReuseButton from '../ReuseButton';
import Card from '../AddCard';
import {
  addPayments,
  fetchPayments,
  updatePayments,
} from '../../../api/enterprise/enterprise';
import { alertMessage } from '../../../common/Common';
import visa from '../../../images/cards/visa.png'
import master from '../../../images/cards/master.png'
import diners from '../../../images/cards/Dinners.png'
import unionpay from '../../../images/cards/union.png'
import discover from '../../../images/cards/Discover.png'
import cartes from '../../../images/cards/cartes.png'
import jcb from '../../../images/cards/jcb.png'
import amex from '../../../images/cards/amex.png'

const PaymentMethod = ({ type }) => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [error, showError] = useState(false);
  const [yearError, showYearError] = useState(false);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const params = useParams();
  const user = localStorage.getItem('user');
  const userDetails = user!==''? JSON.parse(user) : { };

  const getPayments = () => {
    setInProgress(true);
    const entId = type === 'enterprise' ? params.id : 0;
    const userId = type === 'enterprise' ? 0 : params.id;
    fetchPayments(entId, userId).then((result) => {
      if (result.success) {
        setInProgress(false);
        const { data } = result;
        setPaymentData(data);
      } else {
        alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewCard = () => {
    setShowViewModal(true);
  };
  const updateCard = () => {
    setMonth(paymentData.expMonth);
    setYear(paymentData.expYear);
    setShowUpdateModal(true);
  };
  const handleToken = (tokenObj) => {
    const formData = {};
    formData.paymentMethodId = tokenObj.id;
    formData.enterpriseId = type === 'enterprise' ? params.id : 0;
    formData.userId = type === 'enterprise' ? 0 : params.id;
    addPayments(formData).then((result) => {
      if (result.success) {
        getPayments();
        alertMessage('CARD HAS BEEN ADDED SUCCESSFULLY', 'success', 3);
      } else {
        alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
      }
    });
  };
  const handleOnModalClose = () => {
    setShowViewModal(false);
  };
  const handleOnUpdateClose = () => {
    setShowUpdateModal(false);
  };

  const monthChange = (e) => {
    setMonth(e.target.value);
    if (e.target.value > 12) {
      showError(true);
    } else {
      showError(false);
    }
  };
  const yearChange = (e) => {
    setYear(e.target.value);
    if (e.target.value < moment().year()) {
      showYearError(true);
    } else {
      showYearError(false);
    }
  };

  const checkCard = (card) => {
    switch(true){
        case card==='visa':
          return <img className='cardImage' src={visa} alt='visa'/>
        case card==='mastercard':
          return <img className='cardImage' src={master} alt='master'/>
        case card==='amex':
          return <img className='cardImageAmex' src={amex} alt='amex'/>
        case card==='discover':
          return <img className='cardImage' src={discover} alt='amex'/>
        case card==='diners':
          return <img className='cardImage' src={diners} alt='amex'/>
        case card==='jcb':
          return <img className='cardImage' src={jcb} alt='amex'/>
        case card==='unionpay':
          return <img className='cardImage' src={unionpay} alt='amex'/>
    }
  }

  const handleSubmit = () => {
    setInProgress(true);
    const formData = {};
    formData.enterpriseId = type === 'enterprise' ? params.id : 0;
    formData.expMonth = month;
    formData.expYear = year;
    formData.userId = type === 'enterprise' ? 0 : params.id;
    updatePayments(formData).then((result) => {
      if (result.success) {
        setInProgress(false);
        alertMessage('CARD HAS BEEN UPDATED SUCCESSFULLY', 'success', 3);
        handleOnUpdateClose();
        getPayments();
      } else {
        alertMessage(
          result.errors.message.errorMessage
            .substr(0, result.errors.message.errorMessage.indexOf('.'))
            .toUpperCase(),
          'error',
          3,
        );
        setInProgress(false);
      }
    });
  };

  const renderViewModel = () => {
    return (
      <BaseModal width="40%" onCancel={() => handleOnModalClose()}>
        <Card closeModal={handleOnModalClose} sendToken={handleToken} />
      </BaseModal>
    );
  };
  const renderUpdateModel = () => {
    return (
      <BaseModal width="40%" onCancel={() => handleOnUpdateClose()}>
        <Row className="swal-footer">
          <Col lg={24} className="cardHeader">
            Update Expiry Date
          </Col>
          <Col lg={24}>
            <b> Expiry Details :</b>
            &nbsp;
            <Input
              type="text"
              placeholder="MM"
              autoComplete='new-password'
              maxLength="2"
              value={month}
              style={{ width: '10%', marginLeft: '20px', marginRight: '20px' }}
              onChange={monthChange}
            />
            <Input
              type="text"
              placeholder="YYYY"
              autoComplete='new-password'
              maxLength="4"
              value={year}
              style={{ width: '14%', marginRight: '20px' }}
              onChange={yearChange}
            />
          </Col>
          <Col lg={24} className="errorMessage pad-top-15">
            {error && <span>Month can't be greater than 12</span>} &nbsp;
            {yearError && <span>Year can't be less than current year</span>}
          </Col>
          <Col lg={24}>
            <ReuseButton
              text="UPDATE"
              disabled={year === '' || month === '' || error || yearError}
              className="btn-style  enterpriseOptionbuttons btn-success"
              onClick={() => {
                handleSubmit();
              }}
            />
          </Col>
        </Row>
      </BaseModal>
    );
  };

  return (
    <div className="Basecontainer">
      <Spin spinning={inProgress}>
        <Row gutter={30}>
          <Col offset={6} span={12} className="tablePadding mar-top-20">
            {params.id !== userDetails.companyId}
            <p className="TitleText mar-bottom-20">
              {(params.id == userDetails.companyId || (params.id == userDetails.id && params.name == userDetails.firstName + ' ' + userDetails.lastName))?
              " View your payment information here.":
              ` ${type === 'enterprise'
              ? " View the enterprise's payment information here."
              : " View the users's payment information here."}`
            }

            </p>
          </Col>
          <Col span={6} className="tablePadding mar-top-20">
            <p className="TitleText mar-bottom-20">
              {(paymentData.message === undefined && params.id == userDetails.companyId && params.name == userDetails.company ) && (
                <Button
                  className="floatRight btn-style  enterpriseOptionbuttons btn-success"
                  onClick={updateCard}
                >
                  UPDATE CARD DETAILS
                </Button>
              )}
              {(paymentData.message === undefined && params.id == userDetails.id && params.name == userDetails.firstName + ' ' + userDetails.lastName) && (
                <Button
                  className="floatRight btn-style  enterpriseOptionbuttons btn-success"
                  onClick={updateCard}
                >
                  UPDATE CARD DETAILS
                </Button>
              )}
              {(paymentData.message !== undefined && (params.id == userDetails.companyId &&  params.name == userDetails.company)) && (
               <Button
               className="floatRight btn-style  enterpriseOptionbuttons btn-info"
               onClick={addNewCard}
             >
               ADD PAYMENT METHOD
             </Button>
              )}
              {(paymentData.message !== undefined &&  (params.id == userDetails.id && params.name == userDetails.firstName + ' ' + userDetails.lastName)) && (
               <Button
               className="floatRight btn-style  enterpriseOptionbuttons btn-info"
               onClick={addNewCard}
             >
               ADD PAYMENT METHOD
             </Button>
              )}
            </p>
          </Col>
          <Col lg={24} className="tablePadding">
            {paymentData.message === undefined ? (
              <Col lg={24} className="paymentPadding">
                <p className="payments">

                  Payment Method: {(paymentData && paymentData.brand) && checkCard(paymentData.brand)}
                </p>
                <p className="payments">
                  Cardholder Name:{' '}
                  {type==='enterprise' ? ` ${paymentData.enterprise && paymentData.enterprise.name}`:` ${paymentData.user && paymentData.user.firstName}  ${paymentData.user && paymentData.user.lastName!==null ? ' '+  paymentData.user.lastName :''} `}

                </p>
                <p className="payments">
                  Card Number: **** **** ****{' '}
                  {paymentData && paymentData.last4digits}
                </p>
                <p className="payments">
                  Card Exp: {paymentData && paymentData.expMonth} /{' '}
                  {paymentData && paymentData.expYear}
                </p>
              </Col>
            ) : (

              <Col lg={24} className="LoginLayout text-center paymentText paymentPadding1">
                {(params.id == userDetails.companyId || (params.id == userDetails.id && params.name == userDetails.firstName + ' ' + userDetails.lastName))?
                'PLEASE ADD A PAYMENT METHOD IN ORDER TO USE YOUR TAG(S).':
                'NO PAYMENT METHOD HAS BEEN ADDED YET'
              }

              </Col>
            )}
            {paymentData.message === undefined && <Divider style={{ border: '2px solid #EEEEEE' }} />}
          </Col>
          {showViewModal && renderViewModel()}
          {showUpdateModal && renderUpdateModel()}
        </Row>
      </Spin>
    </div>
  );
};

export default PaymentMethod;
