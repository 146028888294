// TODO: import each component separetly from antd to avoid including entire antd into packs
// other wise use babel-plugin-import for this and remove style import in App.jsx. Check antd documentation
import {
  Alert,
  Avatar,
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Badge,
  Row,
  Tooltip,
  Select,
  Spin,
  Switch,
  Table,
  Upload,
  List,
  Tabs,
  TimePicker,
  Collapse,
  Card,
  Carousel,
  Radio,
  Checkbox,
  Breadcrumb,
} from 'antd';
import { Icon } from '@ant-design/compatible';

const { Content, Header, Sider } = Layout;

const { Option } = Select;
const { TextArea } = Input;
const { Search } = Input;

export {
  Alert,
  Avatar,
  AutoComplete,
  Button,
  Col,
  Badge,
  Content,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Header,
  Tooltip,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  Option,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Sider,
  Spin,
  Switch,
  Table,
  TextArea,
  Upload,
  List,
  Tabs,
  TimePicker,
  Collapse,
  Card,
  Radio,
  Checkbox,
  Search,
  Icon,
  Breadcrumb,
  Carousel,
};
