import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as qs from 'query-string';
import { Row, Col, Card, Spin } from '../common/AntdUiComponents';
import OverviewCard from '../utils/reusableComponents/OverviewCard';
import reject from '../images/reject.png';
import Group from '../images/Group.png';
import loadingImg from '../images/loading.png';
import graph from '../images/graph.png';
import exclamation from '../images/exclamation.png';
import { getDashboardsListApi } from '../api/user';
import { alertMessage } from '../common/Common';
import { fetchEnterpriseUsers } from '../api/enterprise/enterpriseUser';

const Overview = () => {
  const [defectiveTags, setDefectiveTags] = useState(0);
  const [orderedTags, setOrderedTags] = useState(0);
  const [paymentIssues, setPaymentIssues] = useState(0);
  const [transactions, setTransactions] = useState(0);
  const [users, setUsers] = useState(0);
  const [entUsers, setEntUsers] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem('user');
  const userDetails = user !== '' ? JSON.parse(user) : {};
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search);

  const getDashboardsList = () => {
    setLoading(true);
    getDashboardsListApi().then(result => {
      if (result.success) {
        setLoading(false);
        setDefectiveTags(result.data.defectiveTags);
        setOrderedTags(result.data.orderedTags);
        setPaymentIssues(result.data.paymentIssues);
        setTransactions(result.data.transactions);
        setUsers(result.data.users);
      } else {
        setLoading(false);
        // alertMessage('SOMETHING WENT WRONG', 'error', 3);
      }
    });
  };

  const getUsers = () => {
    fetchEnterpriseUsers(userDetails.company, 1, 10000).then(result => {
      if (result.success) {
        const pagination = result.pagination;
        setEntUsers(pagination.totalCount);
      } else {
      }
    });
  };

  const checkIfLoggedIn = () => {
    if (parsed.fromemail == 'true') {
      localStorage.clear();
      window.location.reload();
    } else {
      getDashboardsList();
      if (userDetails.userType === 'ENTERPRISE') {
        getUsers();
      }
    }
  };

  useEffect(() => {
    checkIfLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectPayments = () => {
    history.push(
      `/unytag/web/enterprise/${userDetails.company}/${
        userDetails.companyId
      }/${'Payment Method'}`
    );
  };

  return (
    <div>
      <Spin spinning={loading}>
        <p className='ContainerTitleHeader'>
          <span className='PageTitle'>
            Overview ({moment().format('MM/DD/YYYY')})
          </span>
        </p>
        <Row gutter={48} className='overviewBox'>
          <Col md={9}>
            <div className='cardWith'>
              <Link to='/unytag/web/allTransactions'>
                <OverviewCard
                  baseicon={Group}
                  count={transactions}
                  category='ALL TRANSACTIONS'
                />
              </Link>
            </div>
          </Col>
          <Col md={9}>
            <div className='cardWith'>
              <Link to='/unytag/web/orderedtags'>
                <OverviewCard
                  baseicon={loadingImg}
                  count={orderedTags}
                  category='ORDERED TAGS'
                />
              </Link>
            </div>
          </Col>
          <Col md={6} className='textRightAll'>
            <div className='cardWith'>
              <Link
                to={
                  userDetails && userDetails.userType === 'UNYTAG'
                    ? '/unytag/web/users'
                    : `/unytag/web/accounts/${userDetails.company}/${userDetails.companyId}`
                }>
                <OverviewCard
                  baseicon={graph}
                  count={
                    userDetails && userDetails.userType === 'ENTERPRISE'
                      ? entUsers
                      : users
                  }
                  category='USERS'
                />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className='mar-top-30 overviewBox' gutter={48}>
          <Col md={9}>
            <Row>
              <Col xs={24}>
                <div className='cardWith'>
                  <Link to='/unytag/web/defectiveTags'>
                    <OverviewCard
                      baseicon={exclamation}
                      count={defectiveTags}
                      category='DEFECTIVE TAGS'
                    />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='mar-top-30' xs={24}>
                <div className='cardWith'>
                  <Link to='/unytag/web/paymentIssues'>
                    <OverviewCard
                      baseicon={reject}
                      count={paymentIssues}
                      category='ISSUES WITH PAYMENT'
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={15}>
            <Card className='OverviewStatusCard'>
              <p className='OverviewcardTitle'>RFID Tag Notification Center</p>
              <hr className='OverviewLine' />
              <span>
                {userDetails && userDetails.userType === 'ENTERPRISE' && (
                  <Row>
                    <Col xs={16}>
                      {' '}
                      <span className='Overviewlinktag'>
                        Please add a payment method to enable use of your tags.
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      className='OverviewlinkPayment'
                      onClick={redirectPayments}>
                      {' '}
                      <u style={{ cursor: 'pointer' }}>Add Payment</u>
                    </Col>
                  </Row>
                )}
                {userDetails && userDetails.userType !== 'ENTERPRISE' && (
                  <Fragment>
                    <u className='Overviewlinktag1'>
                      We need to figure out the notifications that will go here.
                    </u>
                    <br />
                  </Fragment>
                )}
                <span className='OverviewTime'>2 hours ago</span>
              </span>
              <hr className='OverviewLine' />
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Overview;
