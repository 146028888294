/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { Row, Col, Form, Select } from '../../common/AntdUiComponents';
import AddressForm from '../../common/Addressform';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TollForm = ({
  handleChange,
  handleOnAddressChange,
  handleRoleChange,
  submitForm,
  state,
  roles,
  hideCreate,
  disableFields,
  error,
  errors,
  disableBtn,
  onFocus
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const setEmail = () => {
      form.setFieldsValue({
        email: state.email,
        name: state.name,
        role: state.role,
      });
    };
    setEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, error, errors]);


  return (
    <Form layout='vertical' form={form}>
      <div className='addtolls'>
        <Row style={{ paddingTop: '5%', paddingBottom: '5%' }} gutter={20}>
          <Col span={10}>
            <Form.Item
              name='name'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please enter a name!',
                  validationTrigger: 'onBlur',
                },
                () => ({
                  validator(rule, value) {
                    if (value===undefined) {
                      return Promise.resolve();
                    }
                    if ((value.length===0)&& value.trim() === '') {
                      return Promise.resolve();
                    }

                    if((value.length>0)&& value.trim() !== ''){
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('Please enter a valid name.')
                    );
                  },
                }),
              ]}
              label='FULL NAME'
              className='authlabels'>
              <ReuseInputField
                className='TagInput'
                width='100%'
                placeholder='Full Name'
                value={state.name}
                onChange={handleChange('name')}
                disabled={disableFields}
              />
            </Form.Item>
          </Col>
          <Col offset={4} span={10}>
            <Form.Item
              name='email'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please enter email!',
                  validationTrigger: 'onBlur',
                },
                {
                  type: 'email',
                  message: 'Please provide a valid email address!',
                },
              ]}
              label='WORK EMAIL ADDRESS'
              className='authlabels'>
              <ReuseInputField
                className='TagInput'
                width='100%'
                type='email'
                placeholder='johndoe@mail.com'
                value={state.email}
                disabled={disableFields}
                onChange={handleChange('email')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingBottom: '5%' }} gutter={20}>
          <Col span={10}>
            <span className='TagModalTitle'>TOLL AUTHORITY NAME </span>
            <br />
            <ReuseInputField
              className='TagInput'
              width='100%'
              placeholder='Toll Authority Name'
              value={state.toll}
              disabled={disableFields}
              onChange={handleChange('toll')}
            />
          </Col>
          <Col
            offset={4}
            span={10}
            className={`companytolladdress ${error ? 'errorAddress' : ''}`}>
            <span className='TagModalTitle'>
              COMPANY ADDRESS <span className='star-required'>*</span>{' '}
            </span>
            <br />

            <AddressForm
              autoComplete
              twoRows
              showReqFields
              address={state.address}
              onFocus={onFocus}
              disabled={disableFields}
              onChange={(element, value, obj) =>
                handleOnAddressChange('address', element, value, obj)
              }
            />
            {error && <span className='errorMessageAddress'>{errors} in company address</span>}
          </Col>
        </Row>
        <Row style={{ paddingBottom: '5%' }} gutter={20}>
          <Col span={10}>
            <span className='TagModalTitle'>PHONE NUMBER</span>
            <br />
            <PhoneInput
              country='us'
              onlyCountries={['us', 'in']}
              specialLabel=''
              value={state.phone}
              disabled={disableFields}
              onChange={handleChange('phone')}
            />
          </Col>
          <Col offset={4} span={10}>
            <Form.Item
              name='role'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please select a role!',
                  validationTrigger: 'onBlur',
                },
              ]}
              label='ROLE'
              className='authlabels'>
              <Select
                placeholder='Select one'
                value={state.role}
                style={{ width: '100%' }}
                disabled={disableFields}
                onChange={e => handleRoleChange('role', e)}>
                {roles.map(role => (
                  <Select.Option key={role} value={role}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
      {!hideCreate && (
        <div className='createWrapper'>
          <ReuseButton
            text='CREATE'
            className={
              state.name === '' ||
              state.email === '' ||
              state.address === '' ||
              error ||
              state.role === ''
                ? 'CreateTag'
                : 'AddCreateTag'
            }
            disabled={
              state.name.trim() === '' ||
              state.email === '' ||
              error ||
              state.address === '' ||
              state.role === '' ||
              disableBtn||
              !re.test(String(state.email).toLowerCase())
            }
            onClick={submitForm}
          />
        </div>
      )}
    </Form>
  );
};

export default TollForm;
