/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import UserCategory from './CategoryList';
import UserAccounts from './UserAccounts';
import TripHistory from '../../utils/reusableComponents/tripHistory/TripHistory';
import PaymentMethod from '../../utils/reusableComponents/payments/PaymentMethod';
import { Spin } from '../../common/AntdUiComponents';
import UserProfile from './UserProfile';

const UserInfo = () => {
  const params = useParams();

  const [title, setTitle] = useState(params.name);
  const [listVisible, setListVisible] = useState('category');
  const [inProgress] = useState(false);
  const [userData] = useState([]);
  const [searchField, setSearchField] = useState('');

  const handleTitle = (langValue, nextState) => {
    setTitle(langValue);
    setListVisible(nextState);
  };

  const handleSearch = value => {
    if (listVisible === 'category' || listVisible === 'Trip History & Statements') {
      setSearchField(value)
    }
  };


  return (
    <div>
      <Spin spinning={inProgress}>
        {listVisible !== 'Users' && listVisible !== 'Profile' && (
          <p className="ContainerTitleHeader">
            {listVisible === 'Trip History & Statements' ? (
              ''
            ) : listVisible === 'Payment Method' ? (
              <span className="PageTitle">Payment Method </span>
            ) : (
              listVisible !== 'Users' && (
                <span className="PageTitle">{title} </span>
              )
            )}
{listVisible !== 'Trip History & Statements' && <ReuseInputField placeholder="Search..." className="SearchBar"  onChange={handleSearch}/>}

          </p>
        )}
        {listVisible === 'category' ? (
          <UserCategory onSelectTitle={handleTitle} name={title}  dataValue={searchField}/>
        ) : listVisible === 'Profile' ? (
          <UserProfile />
        ) : listVisible === 'userProfile' ? (
          ''
        ) : listVisible === 'Trip History & Statements' ? (
          <TripHistory type="user" searchField={searchField}/>
        ) : listVisible === 'Payment Method' ? (
          <PaymentMethod type='user'/>
        ) : (
          <UserAccounts userData={userData} name={title} />
        )}
      </Spin>
    </div>
  );
};

export default UserInfo;
