import React from 'react';
import { Input, Icon } from '../../common/AntdUiComponents';

const ReuseInputField = ({
  type,
  placeholder,
  icon,
  width,
  className,
  onChange,
  value,
  height,
  onBlur,
  disabled,
  min
}) => {
  const handleChange = (event) => {
    // eslint-disable-next-line no-shadow
    const { value } = event.target;
    if (onChange) onChange(value);
  };

  return (
    <div style={{ display: 'inline' }}>
      <Input
        type={type}
        autoComplete='new-password'
        placeholder={placeholder}
        prefix={<Icon type={icon} style={{ fontSize: 13 }} />}
        style={{ width: `${width}`, height: `${height}` }}
        className={`Input-Field ${className}`}
        value={value}
        defaultValue={value}
        disabled={disabled}
        onBlur={onBlur}
        min={min}
        onChange={handleChange}
      />
    </div>
  );
};

export default ReuseInputField;
